import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AxiosError } from 'axios';
import { useQueries } from '@tanstack/react-query';
import { IActionButton, ValueOf } from '@appTypes';
import { Card } from '@components/Card';
import { CSVLink } from '@components/Common.styles';
import { QueryStateRouter } from '@components/QueryStateRouter';
import { ServersideTable } from '@components/Table/ServersideTable';
import { ITableRefObject } from '@components/Table/types';
import { useCollectionAgencies } from '@hooks/useCollectionAgencies';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useConfirmDispatchModal } from '@hooks/useModal/hooks/useConfirmDispatchModal';
import { useOriginator } from '@hooks/useOriginator';
import { useQueryState } from '@hooks/useQueryState';
import { useUserDetails } from '@hooks/useUserDetails';
import { IOpsPortalContracts } from '@schemas/opsPortal/types/contracts';
import { IOpsPortalMerchantsList } from '@schemas/opsPortal/types/merchantsList';
import { QueryKeys, Views } from '@utils/constants';
import { downloadCSV } from '@utils/downloadCSV';
import { ButtonVariant, Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';
import { getContractsTableColumns } from '@utils/getContractsTableColumns';
import { getMerchantsOptions } from '@utils/getMerchantsOptions';
import { mapContractsTableData } from '@utils/mapContractsTableData';
import { createContractsCSV } from './createContractsCSV';
import { useGetContractsExtraData } from './queries';
import { IContractTableData } from './types';

interface IContracts {
  view?: ValueOf<typeof Views>;
}

export const Contracts = ({ view = Views.CONTRACTS }: IContracts) => {
  const { getLocaleCurrencyValue, getLocaleDate, translate } = useI18n();
  const { isCompass, originator } = useOriginator();

  const { isInternalOpsUser } = useUserDetails();

  const {
    activateQueryStateHook,
    getFilters,
    queryParams,
    queryState,
    resetFilters,
    stringifiedQueryParams,
    updateQueryParams,
  } = useQueryState(view);

  const queryKeys = {
    [Views.AWAITING_DISPATCH]: QueryKeys.AWAITING_DISPATCH_TABLE_DATA,
    [Views.COLLECTIONS]: QueryKeys.COLLECTIONS_TABLE_DATA,
    [Views.CONTRACTS]: QueryKeys.CONTRACTS_TABLE_DATA,
    [Views.OVERPAID_CONTRACTS]: QueryKeys.OVERPAID_CONTRACTS_TABLE_DATA,
    [Views.PAUSED_CONTRACTS]: QueryKeys.PAUSED_CONTRACTS_TABLE_DATA,
    [Views.TERMINABLE_CONTRACTS]: QueryKeys.TERMINABLE_CONTRACTS,
    [Views.CONTRACTS_BY_UUIDS]: QueryKeys.CONTRACTS_BY_UUIDS,
  };

  const [
    {
      data: contractsData,
      error: contractsError,
      isFetching: isContractsFetching,
      isLoading: isContractsLoading,
    },
    { data: merchantsData, error: merchantsError },
  ] = useQueries<
    [[IOpsPortalContracts, AxiosError], [IOpsPortalMerchantsList, AxiosError]]
  >({
    queries: [
      {
        queryKey: [queryKeys[view], stringifiedQueryParams, originator],
        queryFn: () =>
          fetchDataFromEndpoint(Endpoints.FetchContracts, {
            query: { ...queryParams, originator },
          }),
      },
      {
        queryKey: [QueryKeys.FULL_MERCHANT_LIST, originator],
        queryFn: () =>
          fetchDataFromEndpoint(Endpoints.FetchMerchantsAll, {
            query: { originator },
          }),
      },
    ],
  });

  const contractsExtraData = useGetContractsExtraData(contractsData?.results);

  const merchantsOptions = useMemo(
    () => getMerchantsOptions(merchantsData),
    [merchantsData],
  );

  const filters = getFilters({ merchantsOptions });
  const filtersForAgencyUser = filters.filter(filter =>
    ['merchant', 'search'].includes(filter.id),
  );

  const [dispatchedUuid, setDispatchedUuid] = useState<string>();

  const confirmDispatchModal = useConfirmDispatchModal({
    uuid: dispatchedUuid,
    variant: [ButtonVariant.Danger, ButtonVariant.Sm],
  });

  const { collectionAgencies } = useCollectionAgencies();

  const columns = useMemo(
    () =>
      getContractsTableColumns({
        collectionAgencies,
        button: confirmDispatchModal?.button as IActionButton,
        isCompass,
        isInternalOpsUser,
        setDispatchedUuid,
        translate,
        view,
      }),
    [
      collectionAgencies,
      confirmDispatchModal?.button,
      isCompass,
      isInternalOpsUser,
      translate,
      view,
    ],
  );

  const contractsTableData = mapContractsTableData({
    data: contractsData?.results,
    getLocaleCurrencyValue,
    getLocaleDate,
    isCompass,
    translate,
    contractsExtraData,
  });

  const refToCSVLink = useRef(null);
  const refToDataTable = useRef<ITableRefObject<IContractTableData>>(null);

  const handleDownloadClick = useCallback(() => {
    const { columns: cols, rows } =
      refToDataTable.current as ITableRefObject<IContractTableData>;
    const csv = createContractsCSV({ columns: cols, data: rows, translate });
    downloadCSV({ csv, refToCSVLink });
  }, [translate]);

  const csvButtonText = translate(
    `OPS_PORTAL.BUTTON.DOWNLOAD_${view.toUpperCase()}`,
  );

  const buttons = [
    {
      key: 'downloadCSV',
      clickHandler: handleDownloadClick,
      text: csvButtonText,
    },
  ];

  useEffect(() => {
    activateQueryStateHook();
  }, [activateQueryStateHook]);

  const cardTitle = {
    [Views.AWAITING_DISPATCH]: translate('STATUS.AWAITING_DISPATCH'),
    [Views.CONTRACTS]: translate('OPS_PORTAL.TITLE.ALL_CONTRACTS'),
    [Views.COLLECTIONS]: translate('OPS_PORTAL.TITLE.COLLECTIONS'),
  };

  return (
    <QueryStateRouter error={contractsError || merchantsError}>
      <Card buttons={buttons} title={cardTitle[view as keyof typeof cardTitle]}>
        <>
          <CSVLink download={view} ref={refToCSVLink} />
          <ServersideTable
            columns={columns}
            data={contractsTableData}
            filters={isInternalOpsUser ? filters : filtersForAgencyUser}
            isLoading={isContractsLoading || isContractsFetching}
            manageControlledState={updateQueryParams}
            pagination={{
              limit: queryParams.limit,
              totalItems: contractsData?.count,
            }}
            ref={refToDataTable}
            resetFilters={resetFilters}
            tableState={queryState}
          />
        </>
      </Card>
    </QueryStateRouter>
  );
};

import { QueryKey } from '@tanstack/react-query';
import { Spinner } from '@auth/pages/Auth.styles';
import { useI18n } from '@hooks/useI18n';
import {
  useConfirmContractQuery,
  useTriggerDocumentUploadQuery,
} from '../queries';

interface IUseCompassContractButtons {
  contractDataQueryKey?: QueryKey;
  contractUuid?: string;
  isCompass: boolean;
}

export const useCompassContractButtons = ({
  contractDataQueryKey,
  contractUuid,
  isCompass,
}: IUseCompassContractButtons) => {
  const { translate } = useI18n();
  const { confirmContract, isConfirmingContract } = useConfirmContractQuery({
    contractDataQueryKey,
    contractUuid,
  });

  const { isTriggeringDocumentUpload, triggerDocumentUpload } =
    useTriggerDocumentUploadQuery({
      contractDataQueryKey,
      contractUuid,
    });

  return isCompass
    ? [
        {
          ...(isTriggeringDocumentUpload
            ? { additionalButtonContent: <Spinner extraClassName="pr-5" /> }
            : {}),
          clickHandler: triggerDocumentUpload,
          key: 'uploadCompassContractDocuments',
          text: translate('OPS_PORTAL.BUTTON.TRIGGER_DOCUMENT_UPLOAD'),
        },
        {
          ...(isConfirmingContract
            ? { additionalButtonContent: <Spinner extraClassName="pr-5" /> }
            : {}),
          clickHandler: confirmContract,
          key: 'confirmCompassContractDispatch',
          text: translate('OPS_PORTAL.BUTTON.CONFIRM_CONTRACT_DISPATCH'),
        },
      ]
    : [];
};

import ProgressBar from 'react-bootstrap/ProgressBar';

export function ModalProgressBar({ variant = 'success' }) {
  return (
    <ProgressBar
      animated={true}
      now={100}
      style={{ height: '3px', width: '100%' }}
      variant={variant}
    />
  );
}

import { memo, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { useI18n } from '@hooks/useI18n/useI18n';
import { tablePageSizeOptions } from '@utils/constants';
import { PageSizeLabel } from './Pagination.styled';
import { IPageSizeSetterProps } from './types';

const StyledSelect = styled('select')({
  marginInline: '0.25rem',
});

export const PageSizeSetter = memo(
  ({ pageSize, setPageSize }: IPageSizeSetterProps) => {
    const onChange = useCallback(
      e => setPageSize(Number(e.target.value)),
      [setPageSize],
    );

    const { translate } = useI18n();

    const dropdown = (
      <StyledSelect id="pageSize" onChange={onChange} value={pageSize}>
        {tablePageSizeOptions.map(option => (
          <option key={`pagesize-${option}`} value={option}>
            {option}
          </option>
        ))}
      </StyledSelect>
    );

    return (
      <PageSizeLabel htmlFor="pageSize" isInline={true}>
        {translate('TABLE.SHOW_X_ENTRIES', { x: dropdown })}
      </PageSizeLabel>
    );
  },
);

PageSizeSetter.displayName = 'PageSizeSetter';

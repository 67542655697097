import { createColumnHelper } from '@tanstack/react-table';
import { IActionButton, ReactTableColumns } from '@appTypes';
import { Translate } from '@hooks/useI18n';
import { IEarlySettlementsTableColumn } from './types';
import { ViewEarlySettlementButton } from './ViewEarlySettlementButton';

interface IGetEarlySettlementsTableColumns {
  button?: IActionButton;
  setEarlySettlementQuoteUuid: (uuid: string) => void;
  translate: Translate;
}

export const getEarlySettlementsTableColumns = ({
  button,
  setEarlySettlementQuoteUuid,
  translate,
}: IGetEarlySettlementsTableColumns): ReactTableColumns<IEarlySettlementsTableColumn> => {
  const columnHelper = createColumnHelper<IEarlySettlementsTableColumn>();

  return [
    columnHelper.accessor('createdAt', {
      header: translate('TITLE.DATE'),
    }),
    columnHelper.accessor('expiryDate', {
      header: translate('OPS_PORTAL.TITLE.EXPIRY_DATE'),
    }),
    columnHelper.accessor('settlementAmount', {
      header: translate('PAGE.SETTLEMENTS.SETTLEMENT_AMOUNT'),
    }),
    columnHelper.accessor('quoteStatus', {
      header: translate('OPS_PORTAL.TITLE.QUOTE_STATUS'),
    }),
    columnHelper.accessor('quoteType', {
      header: translate('OPS_PORTAL.TITLE.QUOTE_TYPE'),
    }),
    columnHelper.display({
      id: 'buttonColumn',
      cell: ({ row }) =>
        button ? (
          <ViewEarlySettlementButton
            button={button}
            earlySettlementQuoteUuid={row.original.earlySettlementQuoteUuid}
            setEarlySettlementQuoteUuid={setEarlySettlementQuoteUuid}
          />
        ) : (
          <></>
        ),
    }),
  ];
};

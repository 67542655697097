import { useI18n } from '@hooks/useI18n/useI18n';
import { IContractDetails } from '@schemas/opsPortal/types/contractDetails';
import { TabEventKeys } from '@utils/constants';
import { getDataList } from '@utils/getDataList';
import { useGetCollectionsQuery } from '../queries';
import { mapContractPerformanceData } from './mapContractPerformanceData';

export interface IUseContractPerformanceTab {
  contractData: IContractDetails;
}

export const useContractPerformanceTab = ({
  contractData,
}: IUseContractPerformanceTab) => {
  const { collectionsData, isCollectionsDataError } = useGetCollectionsQuery({
    contractUuid: contractData.contract_uuid,
  });

  const { getLocaleCurrencyValue, getLocaleDate, translate } = useI18n();

  const contractPerformance = {
    activityStatus: translate('OPS_PORTAL.LABEL.ACTIVITY_STATUS'),
    terminationDate: translate('OPS_PORTAL.LABEL.TERMINATION_DATE'),
    performanceStatus: translate('OPS_PORTAL.LABEL.PERFORMANCE_STATUS'),
    contractUuid: translate('OPS_PORTAL.LABEL.CONTRACT_UUID'),
    paymentDueDate: translate('OPS_PORTAL.LABEL.CURRENT_ARREARS_DAY_ZERO'),
    language: translate('LABEL.LANGUAGE'),
    monthlyPaymentAmount: translate('OPS_PORTAL.LABEL.MONTHLY_PAYMENT_AMOUNT'),
    daysInArrears: translate('OPS_PORTAL.LABEL.DAYS_IN_ARREARS'),
    daysInArrearsByReconciliationDate: translate(
      'OPS_PORTAL.LABEL.DAYS_IN_ARREAR_BY_RECONCILIATION_DATE',
    ),
    paymentsDown: translate('OPS_PORTAL.LABEL.PAYMENTS_DOWN'),
    bucket: translate('OPS_PORTAL.LABEL.BUCKET'),
    arrearsAmount: translate('LABEL.ARREARS_AMOUNT'),
    email: translate('LABEL.EMAIL_ADDRESS'),
    numberOfInstallments: translate('LABEL.NUMBER_OF_INSTALMENTS'),
    originalNumberOfInstallments: translate(
      'OPS_PORTAL.LABEL.ORIGINAL_NUMBER_OF_INSTALMENTS',
    ),
    amountPaid: translate('OPS_PORTAL.LABEL.AMOUNT_PAID'),
  };

  const contractPerformanceData = mapContractPerformanceData({
    collectionsData,
    contractData,
    getLocaleCurrencyValue,
    getLocaleDate,
    isCollectionsDataError,
    translate,
  });

  return {
    eventKey: TabEventKeys.CONTRACT_PERFORMANCE,
    title: translate('OPS_PORTAL.TITLE.PERFORMANCE'),
    dataList: getDataList({
      data: contractPerformanceData,
      labels: contractPerformance,
    }),
  };
};

import { useMemo } from 'react';
import { AxiosError } from 'axios';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { usePortalError } from '@hooks/usePortalError';
import { useQueryOnClick } from '@hooks/useQueryOnClick';
import { QueryKeys } from '@utils/constants';
import { downloadFile } from '@utils/downloadFile';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';
import { IDocumentsTableColumn } from './types';

interface IDownloadUrlResponse {
  url: string;
}

export const useGetDownloadExecutionQueries = () => {
  const { handleAxiosError } = usePortalError();

  const useQueryCallbacks = useMemo(
    () => ({
      onSuccess: (url: string | IDownloadUrlResponse) =>
        downloadFile(typeof url === 'string' ? url : url.url),
      onError: (getDocumentsError: AxiosError<IError>) =>
        handleAxiosError(getDocumentsError),
    }),
    [handleAxiosError],
  );

  const { execute: executeOnfidoDownload } = useQueryOnClick<
    string,
    IDocumentsTableColumn
  >(
    [QueryKeys.DOCUMENT_ONFIDO],
    requestObj =>
      fetchDataFromEndpoint(
        `${Endpoints.FetchContractDocumentOnfido}/${requestObj.uuid}`,
        {},
      ),
    useQueryCallbacks,
  );

  const { execute: executeIntrumDownload } = useQueryOnClick<
    string,
    IDocumentsTableColumn
  >(
    [QueryKeys.DOCUMENT_INTRUM],
    requestObj =>
      fetchDataFromEndpoint(
        `${Endpoints.FetchContractDocumentIntrum}/${requestObj.uuid}`,
        {},
      ),
    useQueryCallbacks,
  );

  const { execute: executeCustomerAgreementDownload } = useQueryOnClick<
    string,
    IDocumentsTableColumn
  >(
    [QueryKeys.DOCUMENT_CUSTOMER_AGREEMENT],
    requestObj =>
      fetchDataFromEndpoint(
        `${Endpoints.FetchContractDocumentCustomerAgremeent}/${requestObj.uuid}`,
        {},
      ),
    useQueryCallbacks,
  );

  const { execute: executeTermsAndConditionsDownload } = useQueryOnClick<
    string,
    IDocumentsTableColumn
  >(
    [QueryKeys.DOCUMENT_TERMS_AND_CONDITIONS],
    requestObj =>
      fetchDataFromEndpoint(
        `${Endpoints.FetchContractDocumentTermsAndConditions}/${requestObj.uuid}`,
        {},
      ),
    useQueryCallbacks,
  );

  const { execute: executeOpsUserDocumentsDownload } = useQueryOnClick<
    string,
    IDocumentsTableColumn
  >(
    [QueryKeys.DOCUMENT_OPS_USER_DOCUMENT],
    requestObj =>
      fetchDataFromEndpoint(
        `${Endpoints.FetchContractDocumentOpsUserDocument}/${requestObj.uuid}`,
        {},
      ),
    useQueryCallbacks,
  );

  return {
    executeOnfidoDownload,
    executeIntrumDownload,
    executeCustomerAgreementDownload,
    executeOpsUserDocumentsDownload,
    executeTermsAndConditionsDownload,
  };
};

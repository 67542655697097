import { useCallback } from 'react';
import { IActionButton } from '@appTypes';
import { CardButton } from '@components/CardButton';
import { IDocumentsTableColumn } from './types';

interface IDeletionConfirmationButtonProps {
  button: IActionButton;
  rowData: IDocumentsTableColumn;
  setDeleteDetails: (deleteDetails: IDocumentsTableColumn) => void;
}

export const DeletionConfirmationButton = ({
  button,
  rowData,
  setDeleteDetails,
}: IDeletionConfirmationButtonProps) => {
  const clickHandler = useCallback(() => {
    setDeleteDetails(rowData);
    button.clickHandler();
  }, [setDeleteDetails, rowData, button]);

  return <CardButton {...button} clickHandler={clickHandler} />;
};

import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { useToast } from '@heidi-pay/heidi-common-fe/hooks';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { IActionButton } from '@appTypes';
import { useHasPermission } from '@hooks/useHasPermission';
import { useI18n } from '@hooks/useI18n/useI18n';
import { usePortalError } from '@hooks/usePortalError';
import { useUserDetails } from '@hooks/useUserDetails';
import { IOpsPortalBlockExternalCollectionsReferral } from '@schemas/opsPortal/types/blockExternalCollectionsReferral';
import { IOpsPortalPauseCommunications } from '@schemas/opsPortal/types/pauseCommunications';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { postDataToEndpoint } from '@utils/postDataToEndpoint';
import { CollectionsActionTypes } from '../../enums';
import {
  IBlockExternalCollectionsAutoReferralRequest,
  IPauseCommunicationsEmailsRequest,
} from '../../utils/types';
import { useTriggerBalanceTimelinePdfDownload } from '../queries';

interface IUseDetailsCardActions {
  collectionsBlockAutoReferral?: IOpsPortalBlockExternalCollectionsReferral;
  externalContractUUID?: string;
  pauseCommunications?: IOpsPortalPauseCommunications;
}

export const useDetailsCardActions = ({
  collectionsBlockAutoReferral,
  externalContractUUID,
  pauseCommunications,
}: IUseDetailsCardActions): IActionButton[] => {
  const queryClient = useQueryClient();
  const { message } = useToast();
  const { translate } = useI18n();

  const { userName } = useUserDetails();
  const { handleAxiosError } = usePortalError();

  const endpoints = {
    pauseCommunications: `${Endpoints.PauseCollectionsCommunications}/${externalContractUUID}`,
    blockExternalCollectionsReferral: `${Endpoints.BlockExternalCollectionsReferral}/${externalContractUUID}`,
  };

  const queryKeys = {
    pauseCommunications: [QueryKeys.PAUSE_COMMUNICATIONS, externalContractUUID],
    blockExternalCollectionsReferral: [
      QueryKeys.COLLECTIONS_BLOCK_AUTO_REFERRAL,
      externalContractUUID,
    ],
    timelineEvent: [
      QueryKeys.COLLECTIONS_TIMELINE_EVENTS,
      externalContractUUID,
    ],
  };

  const isCommunicationPaused = pauseCommunications?.pause_communications;
  const pauseCommunicationAction = isCommunicationPaused
    ? CollectionsActionTypes.UNPAUSE
    : CollectionsActionTypes.PAUSE;

  const isAutoReferralBlocked =
    collectionsBlockAutoReferral?.block_external_collections_auto_referral;
  const collectionsAutoReferralAction = isAutoReferralBlocked
    ? CollectionsActionTypes.UNBLOCK
    : CollectionsActionTypes.BLOCK;

  const canDownloadBalancePdf = useHasPermission([
    'ops_portal.can_download_balance_timeline_pdf',
  ]);

  const updateCommunicationsStatus = useMutation<
    IOpsPortalPauseCommunications,
    AxiosError<IError>,
    IPauseCommunicationsEmailsRequest
  >(
    requestData =>
      postDataToEndpoint({
        endpoint: endpoints.pauseCommunications,
        requestData,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.pauseCommunications);
        queryClient.invalidateQueries(queryKeys.timelineEvent);
        message.success(
          translate(
            `OPS_PORTAL.STATUS.SUCCESSFULLY_${pauseCommunicationAction}_COLLECTION_EMAILS`,
          ),
        );
      },
      onError: error =>
        handleAxiosError(
          error,
          'OPS_PORTAL.STATUS.UNABLE_TO_UPDATE_COLLECTIONS_EMAILS',
        ),
    },
  );

  const updateCollectionsAutoReferralStatus = useMutation<
    IOpsPortalBlockExternalCollectionsReferral,
    AxiosError<IError>,
    IBlockExternalCollectionsAutoReferralRequest
  >(
    requestData =>
      postDataToEndpoint({
        endpoint: endpoints.blockExternalCollectionsReferral,
        requestData,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          queryKeys.blockExternalCollectionsReferral,
        );
        queryClient.invalidateQueries(queryKeys.timelineEvent);

        message.success(
          translate(
            `OPS_PORTAL.STATUS.SUCCESSFULLY_${collectionsAutoReferralAction}_REFERRAL_STATUS`,
          ),
        );
      },
      onError: error =>
        handleAxiosError(
          error,
          'OPS_PORTAL_STATUS.UNABLE_TO_UPDATE_REFERRAL_STATUS',
        ),
    },
  );

  const handlePauseCommunications = useCallback(() => {
    updateCommunicationsStatus.mutate({
      pause_communications: !isCommunicationPaused,
      user: userName,
    });
  }, [isCommunicationPaused, updateCommunicationsStatus, userName]);

  const handleBlockExternalCollections = useCallback(() => {
    updateCollectionsAutoReferralStatus.mutate({
      block_external_collections_auto_referral: !isAutoReferralBlocked,
      user: userName,
    });
  }, [isAutoReferralBlocked, updateCollectionsAutoReferralStatus, userName]);

  const triggerBalanceTimelinePdfDownload =
    useTriggerBalanceTimelinePdfDownload(externalContractUUID);

  const buttons: IActionButton[] = [
    {
      clickHandler: handleBlockExternalCollections,
      key: 'blockExternalCollectionsReferralButton',
      text: translate(
        `OPS_PORTAL.LABEL.${collectionsAutoReferralAction}_REFERRAL_TO_COLLECTORS`,
      ),
      dependencies: [isAutoReferralBlocked !== undefined],
    },
    {
      clickHandler: handlePauseCommunications,
      key: 'pauseCommunicationsButton',
      text: translate(
        `OPS_PORTAL.LABEL.${pauseCommunicationAction}_COLLECTION_EMAILS`,
      ),
      dependencies: [isCommunicationPaused !== undefined],
    },
    {
      clickHandler: triggerBalanceTimelinePdfDownload,
      key: 'balanceTimelinePdfDownload',
      text: translate('OPS_PORTAL.LABEL.BALANCE_TIMELINE_PDF_DOWNLOAD'),
      dependencies: [canDownloadBalancePdf],
    },
  ];

  return buttons.filter(({ dependencies }) => dependencies?.every(dep => dep));
};

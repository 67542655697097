import { ValueOf } from '@appTypes';
import { SupportedLanguages } from '@utils/constants';

type Language = ValueOf<typeof SupportedLanguages>;
const baseUrl = 'https://storage.googleapis.com/';

const merchantGuides = {
  de: 'Ha%CC%88ndler%20Guide',
  en: 'Merchant%20Guide',
  fr: 'Guide%20Vendeur',
  it: 'Guida%20venditore',
};

export const createGuides = (createUrl: (lang: Language) => string) =>
  Object.values(SupportedLanguages).map(lang => ({
    lang,
    url: createUrl(lang),
  }));

export const createMerchantGuideUrl = (lang: Language) =>
  `${baseUrl}heidipay-merchant-guides-prod/HeyLight_${merchantGuides[lang]}.pdf`;
export const createCardChangeUrl = (lang: Language) =>
  `${baseUrl}heidi-consumer-portal-guides/Card%20change%20${lang.toUpperCase()}%20-%20HeyLight.pdf`;

export const guides = {
  MERCHANT_GUIDE: createGuides(createMerchantGuideUrl),
  CARD_CHANGE: createGuides(createCardChangeUrl),
};

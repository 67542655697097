import { useEffect, useMemo, useRef } from 'react';
import { Card } from '@components/Card';
import { QueryStateRouter } from '@components/QueryStateRouter';
import { ServersideTable } from '@components/Table/ServersideTable';
import { ITableRefObject } from '@components/Table/types';
import { useCollectionAgencies } from '@hooks/useCollectionAgencies';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useOriginator } from '@hooks/useOriginator';
import { useQueryState } from '@hooks/useQueryState';
import { useUserDetails } from '@hooks/useUserDetails';
import { TableNames, Views } from '@utils/constants';
import { getContractsTableColumns } from '@utils/getContractsTableColumns';
import { getCursorPaginationCallbacks } from '@utils/getCursorPaginationCallbacks/getCursorPaginationCallbacks';
import { mapContractsTableData } from '@utils/mapContractsTableData';
import { MatchingContractsNotification } from './MatchingContractsNotification';
import {
  useGetContractsByUuidsQuery,
  useGetContractsExtraData,
} from './queries';
import { IContractTableData } from './types';

interface IContracts {
  isLoading: boolean;
  uuids?: string[] | null;
}

export const ContractsByUuids = ({ isLoading, uuids }: IContracts) => {
  const { getLocaleCurrencyValue, getLocaleDate, translate } = useI18n();
  const { isCompass } = useOriginator();
  const { isInternalOpsUser } = useUserDetails();

  const {
    activateQueryStateHook,
    queryParams,
    queryState,
    resetFilters,
    stringifiedQueryParams,
    updateQueryParams,
  } = useQueryState(TableNames.CONTRACTS_BY_UUIDS);

  const refToDataTable = useRef<ITableRefObject<IContractTableData>>(null);

  const {
    contractsByUuidsData,
    contractsByUuidsError,
    isContractsByUuidsFetching,
  } = useGetContractsByUuidsQuery({
    uuids,
    stringifiedQueryParams,
  });

  const { collectionAgencies, isCollectionAgenciesLoading } =
    useCollectionAgencies();

  const columns = useMemo(
    () =>
      getContractsTableColumns({
        collectionAgencies,
        isCompass,
        isInternalOpsUser,
        isConsumer: false,
        translate,
        view: Views.CONTRACTS_BY_UUIDS,
      }),
    [collectionAgencies, isCompass, isInternalOpsUser, translate],
  );

  const contractsExtraData = useGetContractsExtraData(
    contractsByUuidsData?.results,
  );

  const contractsTableData = mapContractsTableData({
    data: contractsByUuidsData?.results,
    getLocaleCurrencyValue,
    getLocaleDate,
    isCompass,
    translate,
    contractsExtraData,
  });

  const { gotoNextPage, gotoPrevPage } = getCursorPaginationCallbacks({
    nextCursor: contractsByUuidsData?.next_cursor,
    prevCursor: contractsByUuidsData?.previous_cursor,
    queryState,
    updateQueryParams,
  });

  useEffect(() => {
    activateQueryStateHook();
  }, [activateQueryStateHook]);

  return (
    <Card title={translate('OPS_PORTAL.TITLE.RISK_TEST_MATCHING_CONTRACTS')}>
      <QueryStateRouter
        error={contractsByUuidsError}
        isLoading={
          isLoading || isContractsByUuidsFetching || isCollectionAgenciesLoading
        }
      >
        <>
          <MatchingContractsNotification
            count={uuids?.length}
            translate={translate}
          />
          <ServersideTable
            columns={columns}
            data={contractsTableData}
            isCursorPagination={true}
            manageControlledState={updateQueryParams}
            pagination={{
              gotoNextPage,
              gotoPrevPage,
              pageSize: queryParams.page_size,
              totalItems: contractsByUuidsData?.count ?? 0,
            }}
            ref={refToDataTable}
            resetFilters={resetFilters}
            tableState={queryState}
          />
        </>
      </QueryStateRouter>
    </Card>
  );
};

import styled from 'styled-components';
import { ButtonType, ButtonVariant } from '@utils/enums';

// Extracts the bootstrap color name from the theme and
// overrides the primary variant with the theme one
const getButtonVariant = (variant: ButtonVariant | ButtonVariant[]) =>
  variant === ButtonVariant.Primary ? 'heylight' : variant;

const getFloatClass = (align?: string) => (align ? `float-${align}` : '');

interface IButton {
  align?: string;
  as?: React.ElementType | keyof JSX.IntrinsicElements;
  to?: string;
  type?: ButtonType;
  variant: ButtonVariant | ButtonVariant[];
}

export const Button = styled.button.attrs(
  ({ align, type = ButtonType.Button, variant }: IButton) => ({
    className: `btn ${
      Array.isArray(variant)
        ? variant.map(v => `btn-${getButtonVariant(v)}`).join(' ')
        : `btn-${getButtonVariant(variant)}`
    } ${getFloatClass(align)}`,
    type,
  }),
)<IButton>`
  margin-block-end: 0.25rem;

  .filtersForm & {
    margin-inline-end: 1rem;
  }
  .card-toolbar &,
  button + & {
    margin-inline-start: 1rem;
  }
  .modal-body & {
    margin-block-end: 1.5rem;
  }
  &.no-margin {
    margin-block-end: 0;
  }
  @media print {
    &[data-html2canvas-ignore] {
      display: none;
    }
  }
`;

import { useCallback } from 'react';
import { IActionButton } from '@appTypes';
import { CardButton } from '@components/CardButton';

interface IConfirmDispatchButtonProps {
  button: IActionButton;
  setDispatchedUuid: (uuid: string) => void;
  uuid: string;
}

export const ConfirmDispatchButton = ({
  button,
  setDispatchedUuid,
  uuid,
}: IConfirmDispatchButtonProps) => {
  const clickHandler = useCallback(() => {
    setDispatchedUuid(uuid);
    button?.clickHandler();
  }, [button, setDispatchedUuid, uuid]);

  const updatedButtonProps = { ...button, clickHandler };

  return <CardButton {...updatedButtonProps} />;
};

import { Translate } from '@hooks/useI18n';
import { FilterTypes } from '@utils/constants';
import { dictionary } from '@utils/dictionary';
import { OriginationChannel } from '@utils/enums';
import { QueryParams } from './types';

interface IGetOriginationChannelFilter {
  isCompass?: boolean;
  queryParams: QueryParams;
  translate: Translate;
}

const heidiOriginationChannelMap = {
  [OriginationChannel.Ecommerce]: dictionary.api_label.ecommerce,
  [OriginationChannel.InStore]: dictionary.api_label.in_store,
};

const compassOriginationChannelMap = {
  [OriginationChannel.Ecommerce]: dictionary.api_label.ecommerce,
  [OriginationChannel.InStore]: dictionary.api_label.payByLink,
};

export const getOriginationChannelFilter = ({
  isCompass = false,
  queryParams,
  translate,
}: IGetOriginationChannelFilter) => {
  return {
    accessorKey: 'originationChannel',
    id: 'originationChannel',
    label: translate('TITLE.CHANNEL'),
    options: [
      { id: '', name: '—' },
      ...Object.keys(OriginationChannel).map(key => {
        const originationChannelMap = isCompass
          ? compassOriginationChannelMap
          : heidiOriginationChannelMap;
        const channel =
          OriginationChannel[key as keyof typeof OriginationChannel];
        const translationKey = originationChannelMap[channel];
        return {
          id: channel,
          name: translate(translationKey),
        };
      }),
    ],
    type: FilterTypes.DROPDOWN,
    value: String(queryParams?.originationChannel),
  };
};

import { createColumnHelper } from '@tanstack/react-table';
import { IActionButton, ReactTableColumns } from '@appTypes';
import { Translate } from '@hooks/useI18n';
import { MerchantStoreButton } from './MerchantStoreButton';
import { IMerchantStoreColumn } from './types';

interface IGetColumnsProps {
  button?: IActionButton;
  setStoreUuid: (storeUuid: string) => void;
  translate: Translate;
}

export const getColumns = ({
  button,
  setStoreUuid,
  translate,
}: IGetColumnsProps): ReactTableColumns<IMerchantStoreColumn> => {
  const columnHelper = createColumnHelper<IMerchantStoreColumn>();

  return [
    columnHelper.accessor('storeName', {
      header: translate('TITLE.STORE_NAME'),
    }),
    columnHelper.accessor('storeNumber', {
      header: translate('TITLE.STORE_NUMBER'),
    }),
    columnHelper.display({
      id: 'buttonColumn',
      cell: ({ row }) => (
        <>
          {button ? (
            <MerchantStoreButton
              button={button}
              setStoreUuid={setStoreUuid}
              uuid={row.original.uuid}
            />
          ) : null}
        </>
      ),
    }),
  ];
};

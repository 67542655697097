import { useMemo } from 'react';
import objectPath from 'object-path';
import { styled } from '@mui/material/styles';
import { HeaderSearch } from '@components/HeaderSearch/HeaderSearch';
import { useConfig } from '@hooks/useConfig';
import { usePortal } from '@hooks/usePortal';
import { useHtmlClassService } from '@metronic/layout/_core/MetronicLayout';
import { LanguageSelectorDropdown } from '@metronic/layout/components/extras/dropdowns/LanguageSelectorDropdown';
import { QuickUserToggler } from '@metronic/layout/components/extras/QuickUserToggler';

interface ITopBarStyled {
  justifyContent: 'space-between' | 'flex-end';
}

const TopBarStyled = styled('div')<ITopBarStyled>(({ justifyContent }) => ({
  width: '100%',
  alignItems: 'center',
  justifyContent,
  '& > :only-child': {
    marginLeft: 'auto',
  },
}));

export function Topbar() {
  const { isOpsPortal } = usePortal();
  const { showLanguageDropdown } = useConfig();

  const uiService = useHtmlClassService();
  const { viewLanguagesDisplay, viewUserDisplay } = useMemo(
    () => ({
      viewLanguagesDisplay:
        objectPath.get(uiService.config, 'extras.languages.display') &&
        showLanguageDropdown,
      viewUserDisplay: objectPath.get(uiService.config, 'extras.user.display'),
    }),
    [showLanguageDropdown, uiService],
  );

  const justifyContent = isOpsPortal ? 'space-between' : 'flex-end';

  return (
    <TopBarStyled className="topbar" justifyContent={justifyContent}>
      <span className="desktop-header-search">
        <HeaderSearch />
      </span>
      {viewLanguagesDisplay ? <LanguageSelectorDropdown /> : null}

      {viewUserDisplay ? <QuickUserToggler /> : null}
    </TopBarStyled>
  );
}

import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { QueryKey } from '@tanstack/react-query';
import { Card } from '@components/Card';
import { GridCol, GridRow } from '@components/Common.styles';
import {
  ScheduledPayments,
  IScheduledPaymentsColumns,
} from '@components/ScheduledPayments';
import { ShoppingCartDetails } from '@components/ShoppingCartDetails';
import { IShoppingCartOrderDetails } from '@components/ShoppingCartDetails/types';
import { Tabs } from '@components/Tabs/Tabs';
import { useGetQrInvoices } from '@hooks/useGetQrInvoices/useGetQrInvoices';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useChangeCardDetailsModal } from '@hooks/useModal/hooks/useChangeCardDetailsModal';
import { useOriginator } from '@hooks/useOriginator';
import { IConsumerPortalArrearsData } from '@schemas/consumerPortal/types/arrearsData';
import { IConsumerPortalOrderDetails } from '@schemas/consumerPortal/types/ordersDetails';
import { IUuid } from '@schemas/consumerPortal/types/settlementsMoneta';
import { PaymentMethods, PaymentTypes, TabEventKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { checkPropertiesExist } from '../utils/checkPropertiesExist';
import { getBillingInfoProps } from '../utils/getBillingInfoProps';
import { getConsumerOrderSummaryProps } from '../utils/getConsumerOrderSummaryProps';
import { getScheduledPayments } from '../utils/getScheduledPayments';
import { ConsumerPayments } from './ConsumerPayments/ConsumerPayments';
import { IScheduledPayment } from './types';
import { BillingInfo, OrderSummaryCard, CompassSupportTextAndLink } from '.';

interface IOrderDetailsDisplayConsumer {
  arrearsData?: IConsumerPortalArrearsData;
  contractUuid?: IUuid;
  orderDetailsData: IConsumerPortalOrderDetails;
  queryKey: QueryKey;
}

export const OrderDetailsDisplayConsumer = ({
  arrearsData,
  contractUuid,
  orderDetailsData,
  queryKey,
}: IOrderDetailsDisplayConsumer) => {
  const { getLocaleCurrencyValue, getLocaleDate, translate } = useI18n();
  const { uuid } = useParams();
  const { isCompass, isHeidiPay, isPagolightPro } = useOriginator();

  const dataObj = orderDetailsData[0];
  const orderPaymentMethod = dataObj?.payment_method;
  const externalContractUuid = dataObj?.external_uuid;

  const {
    hasPayments: hasScheduledPayments,
    hasProducts,
    permissions,
  } = checkPropertiesExist({
    data: dataObj,
    isPagolightPro,
  });

  const billingInfoProps = getBillingInfoProps(dataObj);
  const scheduledPayments: IScheduledPayment[] = hasScheduledPayments
    ? getScheduledPayments({
        getLocaleCurrencyValue,
        getLocaleDate,
        schedule: dataObj.receivables,
      })
    : [];

  const nextPayment: IScheduledPayment = scheduledPayments[0] ?? {
    dueDate: '',
    outstandingAmount: '',
    paymentAmount: '',
  };

  const isDirectDebit = orderPaymentMethod === PaymentMethods.DIRECT_DEBIT;
  const changeCardDetailsModal = useChangeCardDetailsModal({
    isDirectDebit,
    isHeidiPay,
    isPagolightPro,
    orderId: uuid,
    hasHeader: isHeidiPay,
  });
  const billingInformationCardButtons = [
    ...(isDirectDebit && isCompass ? [] : [changeCardDetailsModal?.button]),
  ].filter(Boolean);

  const orderSummaryProps = getConsumerOrderSummaryProps({
    arrearsData,
    getLocaleCurrencyValue,
    getLocaleDate,
    isCompass,
    nextPayment,
    orderDetailsData: dataObj,
    translate,
  });

  const formattedScheduledPayments: IScheduledPaymentsColumns[] =
    scheduledPayments.map(payment => ({
      dueDate: payment.dueDate,
      paymentAmount:
        payment.paymentAmount !== payment.outstandingAmount
          ? `${payment.outstandingAmount} / ${payment.paymentAmount}`
          : payment.paymentAmount,
    }));

  const isRegulatedOrUnregulatedCredit = [
    'regulated credit',
    'unregulated credit',
  ].includes(dataObj.credit_type ?? '');

  const summaryComponent = (
    <OrderSummaryCard
      canShowPaymentButton={
        dataObj.payment_method === PaymentMethods.CARD &&
        !isRegulatedOrUnregulatedCredit
      }
      orderDetailsData={dataObj}
      orderSummaryProps={orderSummaryProps}
      paymentType={PaymentTypes.PREPAYMENT}
      permissions={permissions}
      queryKey={queryKey}
      uuid={uuid as string}
    />
  );

  const FuturePaymentsComponent = (
    <ScheduledPayments payments={formattedScheduledPayments} />
  );
  const ConsumerPaymentsComponent = (
    <ConsumerPayments
      contractUuid={contractUuid}
      getLocaleCurrencyValue={getLocaleCurrencyValue}
      getLocaleDate={getLocaleDate}
      translate={translate}
    />
  );

  const paymentTabsData = [
    {
      component: FuturePaymentsComponent,
      eventKey: TabEventKeys.CONSUMER_FUTURE_PAYMENTS,
      title: translate('TITLE.FUTURE_PAYMENTS_SCHEDULE'),
    },
    {
      component: ConsumerPaymentsComponent,
      eventKey: TabEventKeys.CONSUMER_PAYMENTS,
      title: translate('TITLE.PAYMENTS'),
    },
  ];

  const { downloadQrInvoiceButton } = useGetQrInvoices({
    externalContractUuid,
    paymentMethod: orderPaymentMethod,
    permissions: ['consumer_portal.can_view_qr_invoice'],
    endpoint: Endpoints.FetchConsumerQrInvoices,
  });

  return (
    <>
      {hasScheduledPayments ? (
        <GridRow>
          <GridCol>{summaryComponent}</GridCol>
          <GridCol>
            <Card
              buttons={downloadQrInvoiceButton}
              title={translate('TITLE.ORDER_PAYMENTS')}
            >
              <Tabs
                initialTabKey={TabEventKeys.CONSUMER_FUTURE_PAYMENTS}
                tabsData={paymentTabsData}
                tabsId="consumer-payment-tabs"
              />
            </Card>
          </GridCol>
        </GridRow>
      ) : (
        summaryComponent
      )}
      {hasProducts ? (
        <Card title={translate('TITLE.SHOPPING_CART_DETAILS')}>
          <ShoppingCartDetails
            displayTitle={false}
            orderDetails={dataObj.order_details as IShoppingCartOrderDetails}
          />
        </Card>
      ) : null}

      <Card
        buttons={billingInformationCardButtons}
        title={
          isHeidiPay
            ? translate('TITLE.BILLING_INFORMATION')
            : translate('TITLE.DEBIT_METHOD')
        }
      >
        <BillingInfo {...billingInfoProps} />
        {isCompass && dataObj.payment_method === PaymentMethods.DIRECT_DEBIT ? (
          <Box sx={{ textAlign: 'right' }}>
            <CompassSupportTextAndLink
              supportTextKey="PAGE.ORDERS.TO_CHANGE_THE_IBAN"
              translate={translate}
            />
          </Box>
        ) : null}
      </Card>
    </>
  );
};

import { useCallback } from 'react';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useModal } from '@hooks/useModal';
import { ModalStatusRouter } from '@hooks/useModal/components';
import { getModalDetails } from '@hooks/useModal/getModalDetails';
import { Modal } from '@hooks/useModal/types';
import { MerchantStoreForm } from './MerchantStoreForm';
import {
  useCreateMerchantStoreQuery,
  useGetMerchantStoreQuery,
  useUpdateMerchantStoreQuery,
} from './queries';
import {
  IMerchantStoreFormSubmit,
  IMerchantStoreModalProps,
  IMerchantStoreSubModalProps,
} from './types';

const useMerchantStoreModal = (props: IMerchantStoreSubModalProps): Modal => {
  const { buttonTranslationKey, modalName, storeUuid, variant } = props;
  const { translate } = useI18n();

  const modal = useModal({
    sharedProps: props,
    modalName,
    permissions: ['merchant_portal.can_view_merchant_stores'],
    buttonTranslationKey,
  });

  const { isMerchantStoreDataLoading, merchantStoreData } =
    useGetMerchantStoreQuery(storeUuid);
  const { updateMerchantStore } = useUpdateMerchantStoreQuery(modal.closeModal);
  const { createMerchantStore } = useCreateMerchantStoreQuery(modal.closeModal);

  const handleSubmit = useCallback(
    (data: IMerchantStoreFormSubmit) => {
      if (storeUuid) {
        updateMerchantStore({
          ...data,
          uuid: storeUuid,
        });
      } else {
        createMerchantStore(data);
      }
    },
    [createMerchantStore, storeUuid, updateMerchantStore],
  );

  const formID = 'merchantStoreForm';

  const isLoading = Boolean(storeUuid && isMerchantStoreDataLoading);

  const modalContent = (
    <ModalStatusRouter
      formStatusMessage={modal.formStatusMessage}
      isLoading={isLoading}
      loadingMessage={translate('STATUS.LOADING')}
    >
      <MerchantStoreForm
        formID={formID}
        handleSubmit={handleSubmit}
        merchantStoreData={merchantStoreData}
      />
    </ModalStatusRouter>
  );

  const modalProps = {
    formID,
    closeModal: modal.closeModal,
    isDangerousSubmit: true,
    isLoading,
    modalContent,
    title: translate('TITLE.MERCHANT_STORE'),
    variant,
  };

  return getModalDetails({ modal, modalProps });
};

export const useMerchantStoreCreateModal = (
  props: IMerchantStoreModalProps,
): Modal =>
  useMerchantStoreModal({
    ...props,
    modalName: 'merchantStoreCreateModal',
    buttonTranslationKey: 'BUTTON.CREATE_NEW_MERCHANT_STORE',
  });

export const useMerchantStoreUpdateModal = (
  props: IMerchantStoreModalProps,
): Modal =>
  useMerchantStoreModal({
    ...props,
    modalName: 'merchantStoreUpdateModel',
    buttonTranslationKey: 'BUTTON.EDIT',
  });

import { useCallback } from 'react';
import { IActionButton } from '@appTypes';
import { CardButton } from '@components/CardButton';

interface IMerchantStoreButtonProps {
  button: IActionButton;
  setStoreUuid: (uuid: string) => void;
  uuid: string;
}

export const MerchantStoreButton = ({
  button,
  setStoreUuid,
  uuid,
}: IMerchantStoreButtonProps) => {
  const clickHandler = useCallback(() => {
    setStoreUuid(uuid);
    button?.clickHandler();
  }, [button, setStoreUuid, uuid]);

  const updatedButtonProps = { ...button, clickHandler };

  return <CardButton {...updatedButtonProps} />;
};

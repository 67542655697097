import { QueryStateRouter } from '@components/QueryStateRouter/QueryStateRouter';
import { ClientsideTable } from '@components/Table/ClientsideTable';
import {
  GetLocaleCurrencyValue,
  GetLocaleDate,
  Translate,
} from '@hooks/useI18n/types';
import { useGetMonetaSettlementsQuery } from '@pages/ContractDetails/hooks/queries/useGetMonetaSettlementsQuery';
import { IUuid } from '@schemas/consumerPortal/types/orders';
import { TableNames } from '@utils/constants';
import { getConsumerPaymentsColumns } from './getConsumerPaymentsColumns';
import { mapConsumerPaymentsData } from './mapConsumerPaymentsData';

interface IConsumerPayments {
  contractUuid?: IUuid;
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
  getLocaleDate: GetLocaleDate;
  translate: Translate;
}

export const ConsumerPayments = ({
  contractUuid,
  getLocaleCurrencyValue,
  getLocaleDate,
  translate,
}: IConsumerPayments) => {
  const { error, isLoadingMonetaSettlementsData, monetaSettlementsData } =
    useGetMonetaSettlementsQuery({
      contractUuid,
    });
  const columns = getConsumerPaymentsColumns({ translate });
  const data = mapConsumerPaymentsData({
    data: monetaSettlementsData,
    getLocaleCurrencyValue,
    getLocaleDate,
    translate,
  });

  return (
    <QueryStateRouter error={error} isLoading={isLoadingMonetaSettlementsData}>
      <ClientsideTable
        columns={columns}
        data={data}
        isInTab={true}
        tableName={TableNames.CONSUMER_PAYMENTS}
      />
    </QueryStateRouter>
  );
};

import { useMemo } from 'react';
import objectPath from 'object-path';
import { styled } from '@mui/material/styles';
import { Aside } from '@components/Aside';
import { useHtmlClassService } from '@metronic/layout/_core/MetronicLayout';
import { ScrollTop } from './extras/ScrollTop';
import { Footer } from './footer/Footer';
import { Header } from './header/Header';
import { HeaderMobile } from './header-mobile/HeaderMobile';
import { LayoutInit } from './LayoutInit';

const Content = styled('div')({
  padding: '1.5rem 0',
});

const Container = styled('div')({
  maxWidth: 'none',
  padding: '0 1.5rem',
});

interface ILayout {
  children: JSX.Element;
}

export function Layout({ children }: ILayout) {
  const uiService = useHtmlClassService();

  // Layout settings (cssClasses/cssAttributes)
  const layoutProps = useMemo(
    () => ({
      layoutConfig: uiService.config,
      selfLayout: objectPath.get(uiService.config, 'self.layout'),
      asideDisplay: objectPath.get(uiService.config, 'aside.self.display'),
      desktopHeaderDisplay: objectPath.get(
        uiService.config,
        'header.self.fixed.desktop',
      ),
      contentCssClasses: uiService.getClasses('content', true),
      contentContainerClasses: uiService.getClasses('content_container', true),
      contentExtended: objectPath.get(uiService.config, 'content.extended'),
    }),
    [uiService],
  );

  return layoutProps.selfLayout !== 'blank' ? (
    <>
      {/* begin::Main */}
      <HeaderMobile />
      <div className="d-flex flex-column flex-root heyLight">
        {/* begin::Page */}
        <div className="d-flex flex-row flex-column-fluid page">
          {layoutProps.asideDisplay && <Aside />}
          {/* begin::Wrapper */}
          <div
            className="d-flex flex-column flex-row-fluid wrapper"
            id="kt_wrapper"
          >
            <Header />
            <Content
              className={`${layoutProps.contentCssClasses} d-flex flex-column flex-column-fluid`}
              id="kt_content"
            >
              {/* begin::Entry */}
              {!layoutProps.contentExtended && (
                <div className="d-flex flex-column-fluid">
                  <Container className={layoutProps.contentContainerClasses}>
                    {children}
                  </Container>
                </div>
              )}

              {layoutProps.contentExtended && { children }}
              {/* end::Entry */}
            </Content>
            <Footer />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Page */}
      </div>
      <ScrollTop />
      {/* end::Main */}
      <LayoutInit />
    </>
  ) : (
    // BLANK LAYOUT
    <div className="d-flex flex-column flex-root">{children}</div>
  );
}

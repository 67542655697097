import { SupportedLanguage, useI18n } from '@hooks/useI18n';

interface ILinkUrls {
  privacyPolicy: string;
}

export const useGetLinks = () => {
  const { selectedLanguage } = useI18n();

  const links: Record<SupportedLanguage, ILinkUrls> = {
    en: {
      privacyPolicy: 'https://heylight.com/ch/en/privacy-policy-ch',
    },
    de: {
      privacyPolicy: 'https://heylight.com/ch/de/privacy-policy-ch',
    },
    fr: {
      privacyPolicy: 'https://heylight.com/ch/fr/privacy-policy-ch',
    },
    it: {
      privacyPolicy: 'https://heylight.com/ch/it/privacy-policy-ch',
    },
  };

  return links[selectedLanguage];
};

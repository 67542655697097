import {
  GetLocaleCurrencyValue,
  GetLocaleDate,
  Translate,
} from '@hooks/useI18n';
import { IConsumerPortalSettlementsMoneta } from '@schemas/consumerPortal/types/settlementsMoneta';
import { addPlaceholderForEmptyValues } from '@utils/addPlaceholderForEmptyValues';
import { TransactionEntityTypes } from '@utils/constants';
import { convertBooleanToYesOrNo } from '@utils/convertBooleanToYesOrNo';
import { getTranslatedData } from '@utils/getTranslatedData';
import { IMappedConsumerPaymentsData } from '../types';

interface IMapPaymentDataProps {
  data?: IConsumerPortalSettlementsMoneta;
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
  getLocaleDate: GetLocaleDate;
  translate: Translate;
}

export const mapConsumerPaymentsData = ({
  data,
  getLocaleCurrencyValue,
  getLocaleDate,
  translate,
}: IMapPaymentDataProps): IMappedConsumerPaymentsData[] =>
  data?.settlements?.map(dataRow => {
    const {
      amount,
      payee,
      settlement_date: settlementDate,
      transacted_amount: transactedAmount,
      type: settlementType,
    } = dataRow;

    const transactedAmountString = getLocaleCurrencyValue({
      currency: transactedAmount?.currency,
      value: transactedAmount?.amount,
    });
    const amountString =
      amount.amount !== transactedAmount.amount ? `/${amount.amount}` : '';

    return addPlaceholderForEmptyValues({
      amount: `${transactedAmountString}${amountString}`,
      customerRefund: convertBooleanToYesOrNo(
        payee.type === TransactionEntityTypes.CONSUMER,
        translate,
      ),
      settlementDate: settlementDate && getLocaleDate({ date: settlementDate }),
      settlementType: getTranslatedData({ term: settlementType, translate }),
    });
  }) ?? [];

import { AxiosError } from 'axios';
import { useQueries } from '@tanstack/react-query';
import { useOriginator } from '@hooks/useOriginator';
import { IOpsPortalIban } from '@schemas/opsPortal/types/iban';
import { IOpsPortalMerchantDetails } from '@schemas/opsPortal/types/merchantDetails';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';

interface IUseGetMerchantDetails {
  canViewIban: boolean;
  uuid?: string;
}

export const useGetMerchantDetails = ({
  canViewIban,
  uuid,
}: IUseGetMerchantDetails) => {
  const { originator } = useOriginator();

  const [
    {
      data: dataMerchantDetails,
      error: errorMerchantDetails,
      isLoading: isLoadingMerchantDetails,
    },
    { data: dataIban, isLoading: isLoadingIban },
  ] = useQueries<
    [[IOpsPortalMerchantDetails, AxiosError], [IOpsPortalIban, AxiosError]]
  >({
    queries: [
      {
        queryFn: () =>
          fetchDataFromEndpoint(`${Endpoints.FetchMerchants}/${uuid}`, {
            query: { originator },
          }),
        queryKey: [QueryKeys.MERCHANT_DETAILS, uuid, originator],
      },
      {
        queryFn: () =>
          fetchDataFromEndpoint(`${Endpoints.FetchMerchantsIban}/${uuid}`),
        queryKey: [QueryKeys.IBAN, uuid],
        enabled: canViewIban,
      },
    ],
  });

  const loadingIban = canViewIban && isLoadingIban;

  return {
    dataIban,
    dataMerchantDetails,
    errorMerchantDetails,
    isLoading: loadingIban || isLoadingMerchantDetails,
  };
};

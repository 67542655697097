import { useMemo } from 'react';
import Box from '@mui/material/Box';
import { Translate } from '@hooks/useI18n';
import { SearchItem, SearchListItem } from './styled';

interface IExtraResultsMessage {
  totalApplicationsCount: number;
  totalContractsCount: number;
  translate: Translate;
}

interface ICalculateDetails {
  totalApplicationsCount: number;
  totalContractsCount: number;
  translate: Translate;
}

const calculateDetails = ({
  totalApplicationsCount,
  totalContractsCount,
  translate,
}: ICalculateDetails) => {
  let extra = 0;
  const parts = [];

  if (totalContractsCount > 10) {
    const count = totalContractsCount - 10;
    parts.push(translate('OPS_PORTAL.LABEL.CONTRACTS_COUNT', { count }));
    extra += count;
  }

  if (totalApplicationsCount > 10) {
    const count = totalApplicationsCount - 10;
    parts.push(translate('OPS_PORTAL.LABEL.APPLICATIONS_COUNT', { count }));
    extra += count;
  }
  return { extra, parts };
};

export const ExtraResultsMessage = ({
  totalApplicationsCount,
  totalContractsCount,
  translate,
}: IExtraResultsMessage) => {
  const { extra, parts } = useMemo(
    () =>
      calculateDetails({
        totalApplicationsCount,
        totalContractsCount,
        translate,
      }),
    [totalApplicationsCount, totalContractsCount, translate],
  );

  if (extra === 0) {
    return null;
  }

  return (
    <SearchListItem disabled={true}>
      <SearchItem>
        <Box>
          {translate('OPS_PORTAL.LABEL.MORE_RESULTS_NOT_SHOWN', {
            count: extra,
          })}
        </Box>
        <Box>{`(${parts.join(
          translate('OPS_PORTAL.LABEL.RESULTS_AND'),
        )})`}</Box>
      </SearchItem>
    </SearchListItem>
  );
};

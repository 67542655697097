import { useCallback } from 'react';
import { IActionButton } from '@appTypes';
import { CardButton } from '@components/CardButton';
import { IAllocationDetails } from './types';

interface IAllocationDetailsButtonProps {
  allocationDetails: IAllocationDetails;
  button: IActionButton;
  setAllocationDetails: (allocationDetails: IAllocationDetails) => void;
}

export const AllocationDetailsButton = ({
  allocationDetails,
  button,
  setAllocationDetails,
}: IAllocationDetailsButtonProps) => {
  const clickHandler = useCallback(() => {
    setAllocationDetails(allocationDetails);
    button.clickHandler();
  }, [setAllocationDetails, allocationDetails, button]);

  return <CardButton {...button} clickHandler={clickHandler} />;
};

import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { getStringifiedQueryParams } from '@components/TableV2/helpers/getStringifiedQueryParams';
import { PaginationParams } from '@components/TableV2/types';
import { ISortingParam } from '@hooks/useTableSorting/types';
import { IOpsPortalCollections } from '@schemas/opsPortal/types/collections';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint/fetchDataFromEndpoint';
import { ICollectionsFilterValues } from '../types';

interface IUseGetCollectionsQuery {
  filterValues: ICollectionsFilterValues;
  paginationParams: PaginationParams;
  sortingParam: ISortingParam;
}

export const useGetCollectionsQuery = ({
  filterValues,
  paginationParams,
  sortingParam,
}: IUseGetCollectionsQuery) => {
  const queryParams = getStringifiedQueryParams({
    ...filterValues,
    ...paginationParams,
    ...sortingParam,
  });

  const queryKey = [QueryKeys.COLLECTIONS_TABLE_DATA, queryParams];

  const queryFn = () =>
    fetchDataFromEndpoint(Endpoints.FetchCollections, {
      query: queryParams,
    });

  const {
    data: collectionsData,
    error: collectionsError,
    isLoading: isCollectionsLoading,
  } = useQuery<IOpsPortalCollections, AxiosError>(queryKey, queryFn);

  return {
    collectionsData,
    collectionsError,
    isCollectionsLoading,
  };
};

import {
  createContext,
  useCallback,
  ReactNode,
  useState,
  useMemo,
} from 'react';
import { ISelectedModalProps, ModalSelect } from '@hooks/useModal/ModalSelect';

interface IModalContext {
  removeModal: () => void;
  renderModal: (modalSelectProps: ISelectedModalProps) => void;
}

export const ModalContext = createContext<IModalContext>({
  renderModal: () => {},
  removeModal: () => {},
});

interface IModalProvider {
  children: ReactNode;
}

export const ModalProvider = ({ children }: IModalProvider) => {
  const [modal, setModal] = useState<ISelectedModalProps>();

  const renderModal = useCallback((modelDetails: ISelectedModalProps) => {
    setModal(modelDetails);
  }, []);

  const removeModal = useCallback(() => {
    setModal(undefined);
  }, []);

  const value = useMemo(
    () => ({ renderModal, removeModal }),
    [removeModal, renderModal],
  );

  return (
    <ModalContext.Provider value={value}>
      {children}
      {modal ? <ModalSelect {...modal} /> : null}
    </ModalContext.Provider>
  );
};

/* eslint-disable max-len */
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useHasPermission } from '@hooks/useHasPermission';
import { GetLocaleDate, Translate, useI18n } from '@hooks/useI18n';
import { useOriginator } from '@hooks/useOriginator';
import { IOpsPortalContractsSearch } from '@schemas/opsPortal/types/contractsSearch';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';
import { IHeaderSearch } from '../types';

interface IUserGetContractSearchQuery {
  search: string;
}

interface IFormatContractsSearchData {
  data: IOpsPortalContractsSearch | undefined;
  getLocaleDate: GetLocaleDate;
  translate: Translate;
}

const formatContractSearchData = ({
  data,
  getLocaleDate,
  translate,
}: IFormatContractsSearchData): IHeaderSearch[] => {
  if (!data?.contracts) {
    return [];
  }

  return data.contracts.map(contract => {
    const createdDate = new Date(contract.created_date);
    const createdDateStr = getLocaleDate({
      date: contract.created_date,
      includeTime: true,
    });

    return {
      type: translate('OPS_PORTAL.TITLE.CONTRACT'),
      title: `${createdDateStr} - ${contract.payment_plan_reference}`,
      subTitle: `${contract.merchant_name} - ${contract.full_name}`,
      redirectUrl: `/all_contracts/${contract.external_uuid}`,
      created: createdDate.getTime(),
    };
  });
};

export const useGetContractSearchQuery = ({
  search,
}: IUserGetContractSearchQuery) => {
  const { originator } = useOriginator();
  const { getLocaleDate, translate } = useI18n();
  const hasPermissions = useHasPermission(['ops_portal.view_contract']);

  const {
    data: contractsData,
    error: contractsError,
    isLoading: contractsIsLoading,
  } = useQuery<IOpsPortalContractsSearch, AxiosError>(
    [QueryKeys.CONTRACT_SEARCH, search],
    () =>
      fetchDataFromEndpoint(Endpoints.FetchContractSearch, {
        query: { originator, search },
      }),
    {
      enabled: hasPermissions && Boolean(search),
    },
  );

  return {
    contractsSearchResults: formatContractSearchData({
      data: contractsData,
      getLocaleDate,
      translate,
    }),
    totalContractsCount: contractsData?.total_contract_count ?? 0,
    contractsError,
    contractsIsLoading,
  };
};

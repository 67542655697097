import { useState } from 'react';
import { GridSortModel } from '@mui/x-data-grid';
import { ISortingParam, ITableSortingSettings } from './types';

export const useTableSorting = (): ITableSortingSettings => {
  const defaultSortedParamString = {
    ordering: '',
  };
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [sortSearchParam, setSortParamString] = useState<ISortingParam>(
    defaultSortedParamString,
  );

  const handleSortModelChange = (newSortModel: GridSortModel) => {
    const lastClickedSortItem = newSortModel.find(
      item =>
        !sortModel.some(
          prevItem =>
            prevItem.field === item.field && prevItem.sort === item.sort,
        ),
    );

    setSortModel(newSortModel);
    if (lastClickedSortItem) {
      const { sort } = lastClickedSortItem;
      const prefix = sort === 'desc' ? '-' : '';
      setSortParamString({
        ordering: `${prefix}${lastClickedSortItem.field}`,
      });
    } else if (!newSortModel.length) {
      setSortParamString(defaultSortedParamString);
    }
  };

  return { sortModel, handleSortModelChange, sortSearchParam };
};

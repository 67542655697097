import NoIcon from '@mui/icons-material/Cancel';
import YesIcon from '@mui/icons-material/CheckCircle';
import { styled } from '@mui/material/styles';
import { Translate } from '@hooks/useI18n';

interface ICompeteIcon {
  complete: boolean;
  translate: Translate;
}

interface IStyledIconWrapperProps {
  complete: boolean;
}

const StyledIconWrapper = styled('span')<IStyledIconWrapperProps>`
  display: flex;
  color: ${({ complete }) => (complete ? '#03a603' : '#ea1c00')};
  font-weight: bold;
  gap: 4px;
`;

export const CompeteIcon = ({ complete, translate }: ICompeteIcon) => (
  <StyledIconWrapper complete={complete}>
    {complete ? <YesIcon /> : <NoIcon />}
    {` ${translate(complete ? 'GENERAL.YES' : 'GENERAL.NO')}`}
  </StyledIconWrapper>
);

import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import clsx from 'clsx';
import { NoTranslateWrapper } from '@components/Common.styles';
import { useI18n } from '@hooks/useI18n/useI18n';
import { DropdownTopbarItemToggler } from '@metronic/_partials/dropdowns';
import { toAbsoluteUrl } from '@utils/toAbsoluteUrl';
import { LanguageSelectorButton } from './LanguageSelectorButton';
import { ILanguageObject } from './types';

const languages: ILanguageObject[] = [
  {
    flag: toAbsoluteUrl('/media/svg/flags/162-germany.svg'),
    lang: 'de',
    name: 'Deutsch',
  },
  {
    flag: toAbsoluteUrl('/media/svg/flags/012-uk.svg'),
    lang: 'en',
    name: 'English',
  },
  {
    flag: toAbsoluteUrl('/media/svg/flags/195-france.svg'),
    lang: 'fr',
    name: 'français',
  },
  {
    flag: toAbsoluteUrl('/media/svg/flags/013-italy.svg'),
    lang: 'it',
    name: 'italiano',
  },
];

export function LanguageSelectorDropdown() {
  const { selectedLanguage, translate } = useI18n();
  const currentLanguage = languages.find(x => x.lang === selectedLanguage);

  return (
    <NoTranslateWrapper>
      <Dropdown data-testid="languageSelector" drop="down">
        <Dropdown.Toggle
          as={DropdownTopbarItemToggler}
          id="dropdown-toggle-my-cart"
        >
          <OverlayTrigger
            overlay={
              <Tooltip id="language-panel-tooltip">
                {translate('LABEL.SELECT_LANGUAGE')}
              </Tooltip>
            }
            placement="bottom"
          >
            <div className="btn btn-icon btn-clean btn-dropdown btn-lg mr-1">
              <img
                alt={currentLanguage?.name}
                className="h-25px w-25px rounded"
                src={currentLanguage?.flag}
              />
            </div>
          </OverlayTrigger>
        </Dropdown.Toggle>
        <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
          <ul className="navi navi-hover navi-heylight py-4">
            {languages.map(language => (
              <li
                className={clsx('navi-item', {
                  active: language.lang === currentLanguage?.lang,
                })}
                key={language.lang}
              >
                <LanguageSelectorButton language={language} />
              </li>
            ))}
          </ul>
        </Dropdown.Menu>
      </Dropdown>
    </NoTranslateWrapper>
  );
}

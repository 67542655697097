import { useI18n } from '@hooks/useI18n/useI18n';
import { IContractDetails } from '@schemas/opsPortal/types/contractDetails';
import { TabEventKeys } from '@utils/constants';
import { getDataList } from '@utils/getDataList';
import { mapCustomerDetailsData } from './mapCustomerDetailsData';

export interface IUseContractCustomerDetailsTab {
  contractData: IContractDetails;
}

export const useContractCustomerDetailsTab = ({
  contractData,
}: IUseContractCustomerDetailsTab) => {
  const { getLocaleDate, translate } = useI18n();

  const customerDetailsData = mapCustomerDetailsData({
    data: contractData,
    getLocaleDate,
  });

  const customerDetails = {
    customerUUID: translate('OPS_PORTAL.LABEL.CUSTOMER_UUID'),
    customerName: translate('TITLE.CUSTOMER_NAME'),
    customerContactAddress: translate(
      'OPS_PORTAL.LABEL.CUSTOMER_CONTACT_ADDRESS',
    ),
    dateOfBirth: translate('OPS_PORTAL.LABEL.DATE_OF_BIRTH'),
    email: translate('LABEL.EMAIL_ADDRESS'),
    customerAddress: translate('LABEL.ADDRESS'),
    phoneNumber: translate('LABEL.PHONE_NUMBER'),
    language: translate('LABEL.LANGUAGE'),
  };

  return {
    eventKey: TabEventKeys.CONSUMER_DETAILS,
    title: translate('TITLE.CUSTOMER_DETAILS'),
    dataList: getDataList({
      data: customerDetailsData,
      labels: customerDetails,
    }),
  };
};

import { styled } from '@mui/material/styles';
import { HeaderGroup } from '@tanstack/react-table';
import { Loading } from '@components/Loading';
import { useI18n } from '@hooks/useI18n/useI18n';
import { getColSpan } from './getColSpan';

const CenteredTD = styled('td')({
  textAlign: 'center',
  verticalAlign: 'middle',
});

interface INoTableData<TData> {
  headerGroups: HeaderGroup<TData>[];
  isLoading?: boolean;
}

export const NoTableData = <TData,>({
  headerGroups,
  isLoading,
}: INoTableData<TData>) => {
  const { translate } = useI18n();
  const colSpan = getColSpan(headerGroups);

  return (
    <tr>
      <CenteredTD colSpan={colSpan}>
        {isLoading ? (
          <Loading showSpinner={true} sx={{ justifyContent: 'center' }} />
        ) : (
          translate('STATUS.NO_DATA_AVAILABLE')
        )}
      </CenteredTD>
    </tr>
  );
};

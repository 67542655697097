import { useMemo } from 'react';
import { Tabs } from '@components/Tabs';
import { useContractDocumentsTab } from '@hooks/useContractsDocumentsTab';
import { useHasPermission } from '@hooks/useHasPermission';
import { useI18n } from '@hooks/useI18n/useI18n';
import { IOpsPortalBlockExternalCollectionsReferral } from '@schemas/opsPortal/types/blockExternalCollectionsReferral';
import { IContractDetails } from '@schemas/opsPortal/types/contractDetails';
import { IOpsPortalMonetaContract } from '@schemas/opsPortal/types/monetaContract';
import { IOpsPortalPauseCommunications } from '@schemas/opsPortal/types/pauseCommunications';
import { TabEventKeys } from '@utils/constants';
import {
  useAssignmentHistoryTab,
  useCertificatesOfInterestTab,
  useContractOverviewTab,
} from '../hooks';
import { useCardAttemptsTab } from '../hooks/useCardAttemptsTab';
import { useContractCustomerDetailsTab } from '../hooks/useContractConsumerTab';
import { useContractDetailsTab } from '../hooks/useContractDetailsTab';
import { useContractPaymentScheduleHistoryTab } from '../hooks/useContractPaymentScheduleHistoryTab';
import { useContractPerformanceTab } from '../hooks/useContractPerformanceTab';
import { generateTabData } from '../utils/generateTabData';
import { getPaymentScheduleTableColumns } from '../utils/getPaymentScheduleTableColumns';
import { getPaymentsTableColumns } from '../utils/getPaymentsTableColumns';
import { mapPaymentScheduleData } from '../utils/mapPaymentScheduleData';
import { mapPaymentsData } from '../utils/mapPaymentsData';
import { ContractPaymentScheduleColumns } from '../utils/types';

interface ITabsComponentProps {
  collectionsBlockAutoReferralData?: IOpsPortalBlockExternalCollectionsReferral;
  contractData: IContractDetails;
  contractUuid?: string;
  externalContractUUID?: string;
  isArrearsToBeManagedByHeidi: boolean;
  isCompass: boolean;
  isInternalOpsUser: boolean;
  monetaContractData?: IOpsPortalMonetaContract;
  pauseCommunicationsData?: IOpsPortalPauseCommunications;
}

export const TabsComponent = ({
  collectionsBlockAutoReferralData,
  contractData,
  contractUuid,
  externalContractUUID,
  isArrearsToBeManagedByHeidi,
  isCompass,
  isInternalOpsUser,
  monetaContractData,
  pauseCommunicationsData,
}: ITabsComponentProps) => {
  const { getLocaleCurrencyValue, getLocaleDate, translate } = useI18n();

  const contractActivePaymentScheduleColumns = useMemo(
    () =>
      getPaymentScheduleTableColumns<ContractPaymentScheduleColumns>(translate),
    [translate],
  );

  const contractPaymentsColumns = useMemo(
    () => getPaymentsTableColumns(translate),
    [translate],
  );

  const contractPaymentScheduleData = mapPaymentScheduleData({
    data: monetaContractData,
    getLocaleCurrencyValue,
    getLocaleDate,
    translate,
  });

  const contractPaymentsData = mapPaymentsData({
    data: monetaContractData,
    getLocaleCurrencyValue,
    getLocaleDate,
    translate,
  });

  const tabs = generateTabData({
    contractActivePaymentScheduleColumns,
    contractPaymentsColumns,
    contractPaymentScheduleData,
    contractPaymentsData,
    translate,
  });

  const opsCanViewContractDocumentsTab = useHasPermission([
    'ops_portal.can_view_customer_agreements',
  ]);

  const opsCanViewCertificateOfInterestTab = useHasPermission([
    'ops_portal.list_certificate_of_interests',
  ]);

  const contractOverviewTab = useContractOverviewTab({
    collectionsBlockAutoReferralData,
    contractData,
    contractUuid,
    isArrearsToBeManagedByHeidi,
    isCompass,
    isInternalOpsUser,
    pauseCommunicationsData,
  });

  const contractDetailsTab = useContractDetailsTab({ contractData });
  const contractPerformanceTab = useContractPerformanceTab({ contractData });
  const customerDetailsTab = useContractCustomerDetailsTab({ contractData });
  const assignmentHistoryTab = useAssignmentHistoryTab(externalContractUUID);
  const contractPaymentScheduleHistoryTab =
    useContractPaymentScheduleHistoryTab(monetaContractData);
  const certificatesOfInterestTab = useCertificatesOfInterestTab({
    externalContractUUID,
  });
  const contractDocumentsTab = useContractDocumentsTab({
    contractUuid: contractData.contract_uuid,
  });
  const cardAttemptTab = useCardAttemptsTab(externalContractUUID);

  const tabDataArray = [
    contractOverviewTab,
    contractDetailsTab,
    contractPerformanceTab,
    customerDetailsTab,
    ...tabs,
    assignmentHistoryTab,
    contractPaymentScheduleHistoryTab,
    ...(opsCanViewContractDocumentsTab ? [contractDocumentsTab] : []),
    ...(opsCanViewCertificateOfInterestTab ? [certificatesOfInterestTab] : []),
    cardAttemptTab,
  ];

  return (
    <Tabs
      initialTabKey={TabEventKeys.CONTRACT_OVERVIEW}
      tabsData={tabDataArray}
      tabsId="contract-drilldown-tabs"
    />
  );
};

import { useEffect, useState } from 'react';
import { GridSortModel } from '@mui/x-data-grid';
import { IHandlePagination } from '../types';

interface IUseSyncInitialLoadSearchParamsEffect<T, P> {
  filterHandler?: (values: T) => void;
  handlePagination: IHandlePagination<P>;
  handleSortModelChange?: (sortModel: GridSortModel) => void;
  isCursorPagination?: boolean;
  searchParams: URLSearchParams;
}

interface ISyncPaginationParams<P> {
  cursor?: string;
  handlePagination: IHandlePagination<P>;
  isCursorPagination?: boolean;
  offset?: string;
  pageSize?: string;
}

const syncPaginationParams = <P>({
  cursor,
  handlePagination,
  isCursorPagination = false,
  offset,
  pageSize,
}: ISyncPaginationParams<P>) => {
  // Checks that there is page size and a cursor for cursor paginations
  // Or page size and an offset for limit offset pagination
  if (pageSize && (cursor || offset)) {
    const paginationChangeParams = {
      ...(pageSize ? { pageSize: Number(pageSize) } : null),
      ...(isCursorPagination ? { cursor: cursor ?? null } : null),
      ...(!isCursorPagination && offset
        ? { page: Number(offset) / Number(pageSize) || 0 }
        : null),
    };
    handlePagination(paginationChangeParams as P);
  }
};

export const useSyncURLSearchParamsEffect = <TFilterValues, TPaginationModel>({
  filterHandler,
  handlePagination,
  handleSortModelChange,
  isCursorPagination = false,
  searchParams,
}: IUseSyncInitialLoadSearchParamsEffect<TFilterValues, TPaginationModel>) => {
  const [syncedUrlParams, setSyncedUrlParams] = useState<string>();

  useEffect(() => {
    const searchParamsToString = searchParams.toString();
    const hasUrlParamsChanged =
      syncedUrlParams === undefined || syncedUrlParams !== searchParamsToString;
    if (hasUrlParamsChanged) {
      const urlParams = Object.fromEntries(searchParams);
      const { cursor, limit, offset, ordering, ...filters } = urlParams;
      filterHandler?.({ ...filters } as TFilterValues);

      if (ordering) {
        const isDescending = ordering.startsWith('-');
        const sortDirection = isDescending ? 'desc' : 'asc';
        const loadedSortModel: GridSortModel = [
          {
            field: isDescending ? ordering.replace(/^-+/, '') : ordering,
            sort: ordering ? sortDirection : undefined,
          },
        ];
        handleSortModelChange?.(loadedSortModel);
      }

      syncPaginationParams<TPaginationModel>({
        pageSize: limit,
        offset,
        isCursorPagination,
        cursor,
        handlePagination,
      });
      setSyncedUrlParams(searchParams.toString());
    }
  }, [
    filterHandler,
    handleSortModelChange,
    handlePagination,
    isCursorPagination,
    searchParams,
    setSyncedUrlParams,
    syncedUrlParams,
  ]);
};

import { useCallback } from 'react';
import { IActionButton } from '@appTypes';
import { CardButton } from '@components/CardButton';
import { IEditUserGroup } from '@schemas/merchantPortal/types/editUserGroups';

interface IEditUserButtonProps {
  button: IActionButton;
  setUserProps: (userProps: IEditUserGroup) => void;
  userProps: IEditUserGroup;
}

export const EditUserButton = ({
  button,
  setUserProps,
  userProps,
}: IEditUserButtonProps) => {
  const clickHandler = useCallback(() => {
    setUserProps(userProps);
    button.clickHandler();
  }, [button, setUserProps, userProps]);

  return <CardButton {...button} clickHandler={clickHandler} />;
};

import { useCallback, useEffect, useState } from 'react';
import { ValueOf } from '@appTypes';
import {
  INullableAmountObject,
  ISettlement,
} from '@schemas/opsPortal/types/monetaContract';
import { PaymentTypes, TransactionEntityTypes } from '@utils/constants';

export const useGetSettlementAmount = (
  paymentType: ValueOf<typeof PaymentTypes>,
  contractSettlements: ISettlement[] = [],
) => {
  const [settlementAmount, setSettlementAmount] =
    useState<INullableAmountObject>();
  const [settlementUuid, setSettlementUuid] = useState<string>();

  useEffect(() => {
    if (contractSettlements?.length) {
      const contractSettlement =
        paymentType === PaymentTypes.REIMBURSEMENT
          ? contractSettlements.find(
              settlement =>
                settlement.payor.type === TransactionEntityTypes.CONSUMER,
            )
          : contractSettlements[0];

      if (contractSettlement) {
        const { amount, uuid } = contractSettlement;
        setSettlementAmount(amount);
        setSettlementUuid(uuid);
      }
    }
  }, [contractSettlements, paymentType]);

  const handleSettlementChanged = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const settlement = contractSettlements?.find(
        x => x.uuid === event.target.value,
      );
      if (settlement) {
        setSettlementAmount(settlement.amount);
        setSettlementUuid(settlement.uuid);
      }
    },
    [contractSettlements],
  );

  return {
    handleSettlementChanged,
    settlementAmount,
    settlementUuid,
  };
};

import { useI18n } from '@hooks/useI18n/useI18n';
import { IContractDetails } from '@schemas/opsPortal/types/contractDetails';
import { TabEventKeys } from '@utils/constants';
import { getDataList } from '@utils/getDataList';
import { mapContractDetailsData } from './mapContractDetailsData';

export interface IUseContractDetailsTab {
  contractData: IContractDetails;
}

export const useContractDetailsTab = ({
  contractData,
}: IUseContractDetailsTab) => {
  const { getLocaleCurrencyValue, translate } = useI18n();

  const contractDetailsLabels = {
    originalAmount: translate('OPS_PORTAL.LABEL.ORIGINAL_AMOUNT'),
    amountRefunded: translate('OPS_PORTAL.LABEL.AMOUNT_REFUNDED'),
    amountReimbursedToCustomer: translate(
      'OPS_PORTAL.LABEL.AMOUNT_REIMBURSED_TO_CUSTOMER',
    ),
    currentAmount: translate('OPS_PORTAL.LABEL.CURRENT_AMOUNT'),
    amountPaid: translate('OPS_PORTAL.LABEL.AMOUNT_PAID'),
    writeOffAmount: translate('OPS_PORTAL.LABEL.AMOUNT_WRITTEN_OFF'),
    totalInterest: translate('OPS_PORTAL.LABEL.TOTAL_INTEREST'),
    totalAmount: translate('OPS_PORTAL.LABEL.TOTAL_AMOUNT'),
  };

  const contractDetailsData = mapContractDetailsData({
    data: contractData,
    getLocaleCurrencyValue,
  });

  return {
    eventKey: TabEventKeys.CONTRACT_DETAILS,
    title: translate('OPS_PORTAL.TITLE.DETAILS'),
    dataList: getDataList({
      data: contractDetailsData,
      labels: contractDetailsLabels,
    }),
  };
};

import {
  GetLocaleCurrencyValue,
  GetLocaleDate,
  Translate,
} from '@hooks/useI18n';
import { IAllocationGroup } from '@schemas/opsPortal/types/allocationDetails';
import { sort } from '@utils/sort';
import {
  AllocationTable,
  AllocationGroupContainer,
  AllocationHeading,
  Badge,
} from './Allocations.styles';
import { ApprovalStatus } from './enums';
import { formatAllocationAmountTitle } from './formatAllocationAmountTitle';
import { PostedInMoneta } from './PostedInMoneta';

interface IAllocationProps {
  allocationGroup: IAllocationGroup;
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
  getLocaleDate: GetLocaleDate;
  translate: Translate;
}

export const AllocationGroup = ({
  allocationGroup,
  getLocaleCurrencyValue,
  getLocaleDate,
  translate,
}: IAllocationProps) => {
  const {
    allocation_type: allocationType,
    allocations,
    total_allocated: totalAllocated,
  } = allocationGroup;

  return (
    <AllocationGroupContainer role="region">
      <div className="mb-3">
        <AllocationHeading>
          {formatAllocationAmountTitle(
            translate(
              `OPS_PORTAL.LABEL.BANK_ACCOUNT_RECONCILIATIONS.${allocationType}`,
            ),
            getLocaleCurrencyValue({
              value: totalAllocated?.amount,
              currency: totalAllocated?.currency,
            }),
          )}
        </AllocationHeading>
      </div>

      <AllocationTable>
        <thead>
          <tr>
            <th role="columnheader">{translate('LABEL.AMOUNT')}</th>
            <th role="columnheader">
              {translate('OPS_PORTAL.LABEL.REFERENCE')}
            </th>
            <th role="columnheader">
              {translate('OPS_PORTAL.TITLE.CREATED_DATE')}
            </th>
            <th role="columnheader">
              {translate(
                'OPS_PORTAL.LABEL.BANK_ACCOUNT_RECONCILIATIONS.POSTED_TO_MONETA',
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {[...allocations]
            .sort((a, b) => sort(a.created, b.created))
            .map(allocation => (
              <tr key={allocation.uuid}>
                <td>
                  {getLocaleCurrencyValue({
                    value: allocation.amount.amount,
                    currency: allocation.amount.currency,
                  })}
                  {allocation.automated &&
                  allocation.approval_status === ApprovalStatus.Pending ? (
                    <Badge>
                      {translate('OPS_PORTAL.LABEL.PENDING').toUpperCase()}
                    </Badge>
                  ) : null}
                </td>
                <td>
                  {allocation.payment_plan_reference ??
                    allocation.stripe_reference ??
                    allocation.merchant_settlement_reference ??
                    allocation.merchant_uuid}
                </td>
                <td>
                  {getLocaleDate({
                    date: allocation.created,
                    includeTime: true,
                  })}
                </td>
                <td>
                  <PostedInMoneta
                    allocation={allocation}
                    translate={translate}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </AllocationTable>
    </AllocationGroupContainer>
  );
};

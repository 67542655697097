import { Row } from '@tanstack/react-table';
import { sort } from '@utils/sort';
import { getValueFromRowByKey } from './getValueFromRowByKey';

export const sortNumeric = <T>(
  rowA: Row<T>,
  rowB: Row<T>,
  key: keyof T,
): number => {
  const valA = getValueFromRowByKey({ row: rowA, key }).replace(/[^\d]+/g, '');
  const valB = getValueFromRowByKey({ row: rowB, key }).replace(/[^\d]+/g, '');
  return sort(valA, valB);
};

import EditIcon from '@mui/icons-material/Edit';
import ReportOffIcon from '@mui/icons-material/ReportOff';
import IconButton from '@mui/material/IconButton';
import { useApplicantBlocking } from '@hooks/useApplicantBlocking';

interface IBlockActionsProps {
  applicantUuid: string;
}

export const BlockActions = ({ applicantUuid }: IBlockActionsProps) => {
  const { applicantBlockModalButtons: applicantBlockModalButton } =
    useApplicantBlocking({
      applicantUuid,
    });

  const [modifyProps, removeProps] = applicantBlockModalButton;

  return (
    <div className="no-wrap">
      {modifyProps ? (
        <IconButton
          aria-label={modifyProps.text}
          color="inherit"
          onClick={modifyProps.clickHandler}
        >
          <EditIcon />
        </IconButton>
      ) : null}
      {removeProps ? (
        <IconButton
          aria-label={removeProps.text}
          color="inherit"
          onClick={removeProps.clickHandler}
        >
          <ReportOffIcon />
        </IconButton>
      ) : null}
    </div>
  );
};

import { objectKeysToCamelCase } from '@heidi-pay/heidi-common-fe/utils/objectKeysToCamelCase';
import { GetLocaleCurrencyValue } from '@hooks/useI18n';
import { IContractDetails } from '@schemas/opsPortal/types/contractDetails';
import { addPlaceholderForEmptyValues } from '@utils/addPlaceholderForEmptyValues';
import { IContractDetailsDisplayData } from '../../utils/types';

interface IMapContractDetailsDataProps {
  data: IContractDetails;
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
}

export const mapContractDetailsData = ({
  data,
  getLocaleCurrencyValue,
}: IMapContractDetailsDataProps): IContractDetailsDisplayData => {
  const {
    amount,
    amountRefunded,
    amountReimbursedToCustomer,
    received,
    systemRemainder,
    totalInterest,
    writeOffAmount,
  } = objectKeysToCamelCase(data);

  const totalInterestValue =
    Number(totalInterest.amount) > 0
      ? getLocaleCurrencyValue({
          currency: totalInterest.currency,
          value: totalInterest.amount,
        })
      : null;

  const totalAmountValue = totalInterestValue
    ? getLocaleCurrencyValue({
        currency: amount.currency,
        value: (
          Number(amount.amount) + Number(totalInterest.amount)
        ).toString(),
      })
    : null;

  return addPlaceholderForEmptyValues({
    originalAmount: getLocaleCurrencyValue({
      currency: amount.currency,
      value: amount.amount,
    }),
    ...(totalInterestValue ? { totalInterest: totalInterestValue } : {}),
    ...(totalAmountValue ? { totalAmount: totalAmountValue } : {}),
    currentAmount: getLocaleCurrencyValue({
      currency: systemRemainder.currency,
      value: systemRemainder.amount,
    }),
    amountRefunded:
      amountRefunded?.amount &&
      getLocaleCurrencyValue({
        currency: amountRefunded.currency,
        value: amountRefunded.amount,
      }),
    amountReimbursedToCustomer:
      amountReimbursedToCustomer?.amount &&
      getLocaleCurrencyValue({
        currency: amountReimbursedToCustomer.currency,
        value: amountReimbursedToCustomer.amount,
      }),
    amountPaid:
      received?.amount &&
      getLocaleCurrencyValue({
        currency: received.currency,
        value: received.amount,
      }),
    writeOffAmount:
      writeOffAmount?.amount &&
      getLocaleCurrencyValue({
        currency: writeOffAmount.currency,
        value: writeOffAmount.amount,
      }),
  });
};

import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { DropdownRHF } from '@components/Dropdown/DropdownRHF';
import { FileUpload } from '@components/FileUpload';
import { TextFieldRHF } from '@components/TextField';
import { useI18n } from '@hooks/useI18n/useI18n';
import { UserDocumentClassifications } from '@utils/constants';
import { ValidationErrorKeys } from '@utils/enums';

interface IOpsUserDocumentUploadForm {
  formID: string;
  handleSubmit: (values: object) => void;
}

export const OpsUserDocumentUploadForm = ({
  formID,
  handleSubmit,
}: IOpsUserDocumentUploadForm) => {
  const { translate } = useI18n();

  const validationSchema = Yup.object({
    name: Yup.string().required(translate(ValidationErrorKeys.Required)),
    files: Yup.mixed<File[]>()
      .test('fileLength', translate(ValidationErrorKeys.Required), value =>
        Boolean(value && value.length > 0),
      )
      .required(translate(ValidationErrorKeys.Required)),
  });

  const defaultValues = {};
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema),
  });

  return (
    <FormProvider {...methods}>
      <form id={formID} onSubmit={methods.handleSubmit(handleSubmit)}>
        <TextFieldRHF
          id="name"
          label={translate('OPS_PORTAL.LABEL.NAME')}
          name="name"
        />
        <DropdownRHF
          id="classification"
          label={translate('OPS_PORTAL.LABEL.CLASSIFICATION')}
          name="classification"
          options={Object.values(UserDocumentClassifications).map(value => {
            return {
              id: value,
              name: translate(`OPS_PORTAL.LABEL.${value.toUpperCase()}`),
            };
          })}
        />
        <FileUpload
          id="files"
          label={translate('OPS_PORTAL.TITLE.CHOOSE_FILE')}
          multiple={false}
          name="files"
        />
      </form>
    </FormProvider>
  );
};

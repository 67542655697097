import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';

export const EarlySettlementDataGrid = styled(Grid)({
  '> dl': {
    paddingTop: '1rem',
  },
  marginBottom: '2rem',
});

export const NewSettlementScheduleGrid = styled(Grid)({
  '.table tr': {
    'td,th': {
      padding: '0.25rem 0',
    },
  },
});

import { IGetLimitAndOffset, LimitOffsetPaginationParams } from '../types';

export const addIdToRows = (data: object[]) =>
  data?.map((item: object, index: number) => {
    return { ...item, id: index };
  });

const notBlankOrEmpty = (value: unknown) =>
  value !== null && value !== '' && value !== undefined;

export const removeEmptyKeys = <T extends Record<string, unknown>>(obj: T) =>
  Object.fromEntries(
    Object.entries(obj).filter(entry => notBlankOrEmpty(entry[1])),
  ) as Partial<T>;

export const getLimitAndOffset = ({
  pageNumber,
  pageSize,
}: IGetLimitAndOffset): LimitOffsetPaginationParams => {
  const offset = pageSize * pageNumber;
  return {
    limit: pageSize || 10,
    offset: offset || 0,
  };
};

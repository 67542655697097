import {
  GetLocaleCurrencyValue,
  GetLocaleDate,
  Translate,
} from '@hooks/useI18n';
import {
  INullableAmountObject,
  IDate,
  IOpsPortalEarlySettlement,
} from '@schemas/opsPortal/types/earlySettlement';
import { addPlaceholderForEmptyValues } from '@utils/addPlaceholderForEmptyValues';
import { transformIfAvailable } from '@utils/transformIfAvailable';
import { DisplayIfAvailable } from '@utils/transformIfAvailable/types';

interface IGetEarlySettlementDisplayDataListProps {
  earlySettlement: IOpsPortalEarlySettlement;
  getLocaleCurrencyValue: GetLocaleCurrencyValue;
  getLocaleDate: GetLocaleDate;
  translate: Translate;
}

export const getEarlySettlementDisplayDataList = ({
  earlySettlement,
  getLocaleCurrencyValue,
  getLocaleDate,
  translate,
}: IGetEarlySettlementDisplayDataListProps) => {
  const getAmountString = (amount?: INullableAmountObject) =>
    getLocaleCurrencyValue({
      currency: amount?.currency,
      value: amount?.amount,
    });

  const getFormattedDate = (date: IDate, includeTime = false) =>
    date && getLocaleDate({ date, includeTime });

  const displayIfAvailable: DisplayIfAvailable<IOpsPortalEarlySettlement> = (
    name,
    transform,
  ) => transformIfAvailable(name, earlySettlement, transform);

  const items = {
    early_settlement_quote_uuid: earlySettlement?.early_settlement_quote_uuid,
    created_date: getFormattedDate(earlySettlement?.created_at, true),
    expiry_date: getFormattedDate(earlySettlement?.expiry_date),
    contract_uuid: earlySettlement?.contract_uuid,
    quote_type: translate(`OPS_PORTAL.STATUS.${earlySettlement?.quote_type}`),
    quote_status: translate(
      `OPS_PORTAL.STATUS.${earlySettlement?.quote_status.toUpperCase()}`,
    ),
    settlement_amount: getAmountString(earlySettlement?.settlement_amount),
    valid_days: earlySettlement?.valid_days,
    arrears_amount: getAmountString(earlySettlement?.arrears_amount),
    ...displayIfAvailable('future_payments_made_in_advance', getAmountString),
    due_installments_amount: getAmountString(
      earlySettlement?.due_installments_amount,
    ),
    late_fee_amount: getAmountString(earlySettlement?.late_fee_amount),
    interest_rebate: getAmountString(earlySettlement?.interest_rebate),
    ...displayIfAvailable('new_final_payment_amount', getAmountString),
    ...displayIfAvailable('new_maturity_date', getFormattedDate),
    ...displayIfAvailable('new_payment_amount', getAmountString),
    total_settlement_due: getAmountString(
      earlySettlement?.total_settlement_due,
    ),
  };

  const translateKeys: Record<string, string> = {
    arrears_amount: 'LABEL.ARREARS_AMOUNT',
    contract_uuid: 'OPS_PORTAL.LABEL.CONTRACT_UUID',
    late_fee_amount: 'OPS_PORTAL.LABEL.UNPAID_LATE_FEES_AMOUNT',
    settlement_amount: 'PAGE.SETTLEMENTS.SETTLEMENT_AMOUNT',
  };

  return Object.entries(
    addPlaceholderForEmptyValues<Record<string, string>>(items),
  ).map(([key, value]) => ({
    key: translate(
      translateKeys[key] ?? `OPS_PORTAL.TITLE.${key.toUpperCase()}`,
    ),
    value,
  }));
};

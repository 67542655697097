import { useContext } from 'react';
import { ModalContext } from './ModalProvider';

export const useMountModal = () => {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error('useMountModal must be used within a ModalProvider');
  }

  return context;
};

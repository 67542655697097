import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { IActionButton } from '@appTypes';
import { SortingFunctions } from '@utils/constants';
import { filterDateRange } from '@utils/filterDateRange';
import { CommunicationType, EventType } from './enums';
import { ICommunication, IEvent, Translate } from './types';
import { ViewCommunicationButton } from './ViewCommunicationButton';

interface IGetEventsTimelineColumnsProps {
  button: IActionButton | null;
  setCommunicationProps: (communicationProps: ICommunication) => void;
  translate: Translate;
}

export const EventColumnAccessor = Object.freeze({
  TYPE: 'type',
  CREATED_DATE: 'createdDate',
  TITLE: 'title',
  DESCRIPTION: 'description',
  COMMUNICATION_DIRECTION: 'communicationDirection',
  COMMUNICATION_TYPE: 'communicationType',
  COMMUNICATION_STATUS: 'communicationStatus',
  PAYMENT_CAPTURE_STATUS: 'paymentCaptureStatus',
  PAYMENT_PROCESSING_STATUS: 'paymentProcessingStatus',
});

export const getEventsTimelineColumns = ({
  button,
  setCommunicationProps,
  translate,
}: IGetEventsTimelineColumnsProps) => {
  const columnHelper = createColumnHelper<IEvent>();

  return [
    columnHelper.accessor(EventColumnAccessor.TYPE, {
      header: translate('OPS_PORTAL.LABEL.EVENT_TYPE'),
      sortingFn: SortingFunctions.ALPHANUMERIC,
    }),
    columnHelper.accessor(EventColumnAccessor.CREATED_DATE, {
      header: translate('OPS_PORTAL.TITLE.CREATED_DATE'),
      filterFn: (row, _, filterValue) =>
        filterDateRange({
          accessorKey: EventColumnAccessor.CREATED_DATE,
          filterValue,
          row,
        }),
      sortingFn: SortingFunctions.DATETIME,
    }),
    columnHelper.accessor(EventColumnAccessor.TITLE, {
      header: translate('OPS_PORTAL.LABEL.EVENT_TITLE'),
    }),
    columnHelper.accessor(EventColumnAccessor.DESCRIPTION, {
      header: translate('OPS_PORTAL.LABEL.EVENT_DESCRIPTION'),
    }),
    columnHelper.accessor(EventColumnAccessor.COMMUNICATION_DIRECTION, {
      header: translate('OPS_PORTAL.LABEL.EVENT_COMMUNICATION_DIRECTION'),
    }),
    columnHelper.accessor(EventColumnAccessor.COMMUNICATION_TYPE, {
      header: translate('OPS_PORTAL.LABEL.EVENT_COMMUNICATION_TYPE'),
    }),
    columnHelper.accessor(EventColumnAccessor.COMMUNICATION_STATUS, {
      header: translate('OPS_PORTAL.LABEL.EVENT_COMMUNICATION_STATUS'),
    }),
    columnHelper.accessor(EventColumnAccessor.PAYMENT_CAPTURE_STATUS, {
      header: translate('OPS_PORTAL.LABEL.EVENT_PAYMENT_CAPTURE_STATUS'),
    }),
    columnHelper.accessor(EventColumnAccessor.PAYMENT_PROCESSING_STATUS, {
      header: translate('OPS_PORTAL.LABEL.EVENT_PAYMENT_PROCESSING_STATUS'),
    }),
    columnHelper.display({
      id: 'buttonColumn',
      cell: ({ row }) =>
        Object.values(CommunicationType).includes(
          row.original.communicationType,
        ) && button ? (
          <ViewCommunicationButton
            button={button}
            communicationProps={{
              title: row.original.title,
              type: row.original.communicationType,
              uuid: row.original.model_uuid,
            }}
            setCommunicationProps={setCommunicationProps}
          />
        ) : null,
    }),
  ] as ColumnDef<IEvent, EventType>[];
};

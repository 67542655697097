import { ITabData } from '@components/Tabs/types';
import { TabEventKeys, TableNames } from '@utils/constants';
import { IGenerateTabDataProps } from './types';

export const generateTabData = ({
  contractActivePaymentScheduleColumns,
  contractPaymentScheduleData,
  contractPaymentsColumns,
  contractPaymentsData,
  translate,
}: IGenerateTabDataProps): ITabData[] => {
  const contractPaymentSchedule = {
    eventKey: TabEventKeys.CONTRACT_PAYMENT_SCHEDULE,
    title: translate('OPS_PORTAL.TITLE.ACTIVE_SCHEDULE'),
    data: contractPaymentScheduleData,
    columns: contractActivePaymentScheduleColumns,
    tableName: TableNames.PAYMENT_SCHEDULE,
    className: 'payment-schedule',
    rowClasses: contractPaymentScheduleData.map(entry =>
      !entry.focused ? 'future-entry' : '',
    ),
  };

  const contractPayments = {
    eventKey: TabEventKeys.CONTRACT_PAYMENTS,
    title: translate('TITLE.PAYMENTS'),
    data: contractPaymentsData,
    columns: contractPaymentsColumns,
    tableName: TableNames.PAYMENTS,
  };

  // Keep in this order for tab display purposes
  return [contractPaymentSchedule, contractPayments];
};

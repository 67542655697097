import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

export const SearchContainer = styled(Paper)({
  maxHeight: '240px',
  minWidth: '400px',
  width: '100%',
  overflow: 'auto',
  border: '1px solid #ccc',
});

export const SearchList = styled(List)({
  padding: '0',
});

export const SearchListItem = styled(ListItemButton)({
  padding: '6px 12px',
  borderBottom: '1px solid #eee',
});

export const SearchItem = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

export const SearchTitle = styled(Box)({
  color: '#444',
});

export const SearchSubTitle = styled(Box)({
  fontSize: '0.875em',
  color: '#666',
});

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useQueries } from '@tanstack/react-query';
import { Card } from '@components/Card';
import { NoDataAvailable } from '@components/NoDataAvailable';
import { QueryStateRouter } from '@components/QueryStateRouter';
import { useApplicantBlocking } from '@hooks/useApplicantBlocking';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useQueryState } from '@hooks/useQueryState';
import { IOpsPortalApplications } from '@schemas/opsPortal/types/applications';
import { IOpsPortalConsumerDetails } from '@schemas/opsPortal/types/consumerDetails';
import { selectTimeToAuthExpiry } from '@selectors/selectTimeToAuthExpiry';
import { QueryKeys, TableNames } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';
import { CardDisplay } from './CardDisplay';

export const DetailsCard = () => {
  const { translate } = useI18n();
  const { consumerUuid } = useParams();

  const { queryParams } = useQueryState(TableNames.APPLICATIONS);

  const msUntilTokenExpiry = useSelector(selectTimeToAuthExpiry);

  const [consumer, applications] = useQueries<
    [
      [IOpsPortalConsumerDetails, AxiosError],
      [IOpsPortalApplications, AxiosError],
    ]
  >({
    queries: [
      {
        cacheTime: msUntilTokenExpiry,
        queryFn: () =>
          fetchDataFromEndpoint(`${Endpoints.FetchConsumers}/${consumerUuid}`),
        queryKey: [QueryKeys.CONSUMER_DETAILS, consumerUuid],
        staleTime: msUntilTokenExpiry,
      },
      {
        enabled: Boolean(consumerUuid),
        queryFn: () =>
          fetchDataFromEndpoint(Endpoints.FetchApplications, {
            query: { ...queryParams, consumerUuid: consumerUuid as string },
          }),
        queryKey: [QueryKeys.APPLICATIONS_TABLE_DATA, consumerUuid],
      },
    ],
  });

  const {
    applicantBlockModalButtons: applicantBlockModalButton,
    error: blockStatusError,
    isLoading: isBlockStatusLoading,
    notification,
  } = useApplicantBlocking({
    consumerUuid,
  });

  return (
    <Card
      buttons={applicantBlockModalButton}
      title={translate('OPS_PORTAL.TITLE.CONSUMER_DETAILS')}
    >
      <QueryStateRouter
        error={applications.error ?? consumer.error ?? blockStatusError}
        isLoading={
          applications.isLoading ?? consumer.isLoading ?? isBlockStatusLoading
        }
      >
        <>
          {notification}
          {consumer.data ? (
            <CardDisplay
              applications={applications.data?.results}
              data={consumer.data}
            />
          ) : (
            <NoDataAvailable />
          )}
        </>
      </QueryStateRouter>
    </Card>
  );
};

import { IActionButton } from '@appTypes';
import { AccessController } from '@components/AccessController';
import { Button } from '@components/Common.styles';
import { ButtonVariant } from '@utils/enums';

export const CardButton = ({
  additionalButtonContent,
  clickHandler,
  dependencies,
  scopes,
  text,
  variant = ButtonVariant.Primary,
  ...rest
}: IActionButton) => {
  const dependenciesFulfilled = dependencies?.length
    ? dependencies.every(Boolean)
    : true;

  const buttonProps = {
    onClick: clickHandler,
    variant,
    ...rest,
  };

  const buttonComponent = (
    <Button {...buttonProps}>
      {text}
      {additionalButtonContent}
    </Button>
  );

  if (dependenciesFulfilled) {
    return scopes?.length ? (
      <AccessController scopes={scopes}>{buttonComponent}</AccessController>
    ) : (
      buttonComponent
    );
  }

  return null;
};

import { useMemo } from 'react';
import { useConfig } from '@hooks/useConfig';
import { useGetLinks } from '@hooks/useGetLinks';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useHtmlClassService } from '@metronic/layout/_core/MetronicLayout';

export function Footer() {
  const today = new Date().getFullYear();
  const uiService = useHtmlClassService();
  const { privacyPolicy } = useGetLinks();

  const layoutProps = useMemo(
    () => ({
      footerClasses: uiService.getClasses('footer', true),
      footerContainerClasses: uiService.getClasses('footer_container', true),
    }),
    [uiService],
  );

  const { translate } = useI18n();
  const { showFooter, showFooterPrivacyLink } = useConfig();
  const footerClasses =
    'd-flex flex-column flex-md-row align-items-center justify-content-between';

  return showFooter ? (
    <div
      className={`footer bg-white py-4 d-flex flex-lg-column  ${layoutProps.footerClasses}`}
      id="kt_footer"
    >
      <div className={`${layoutProps.footerContainerClasses} ${footerClasses}`}>
        <div className="text-dark order-2 order-md-1">
          &copy;{' '}
          <span className="text-muted font-weight-bold mr-2">
            2020 - {today.toString()}
          </span>
          <a
            className="text-dark-75 text-hover-primary"
            href="https://heylight.com"
            rel="noopener noreferrer"
            target="_blank"
          >
            {translate('GENERAL.COMPANY_LEGAL_NAME')}
          </a>
        </div>
        <div className="nav nav-dark order-1 order-md-2">
          {showFooterPrivacyLink ? (
            <a
              className="nav-link px-3"
              href={privacyPolicy}
              rel="noopener noreferrer"
              target="_blank"
            >
              {translate('LINK.PRIVACY')}
            </a>
          ) : null}
        </div>
      </div>
    </div>
  ) : null;
}

/* eslint-disable max-lines */
import { InputHTMLAttributes } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import CircularProgress from '@mui/material/CircularProgress';
import MUIFormControlLabel from '@mui/material/FormControlLabel';
import MuiLink from '@mui/material/Link';
import { DataGrid as MUIDataGrid } from '@mui/x-data-grid';

interface IStyledComponentWithClassName {
  extraClassName?: string;
}

export { Button } from './Button';

export const ButtonRow = styled.div`
  align-self: flex-end;
`;

export const Cardlet = styled.div.attrs({ className: 'col-md-6 col-xl-3' })`
  margin-bottom: 20px;
`;

export const CardletTitle = styled.h3`
  padding-block-start: 0;
  padding-block-end: 1rem;
  padding-inline-start: 0;
  padding-inline-end: 0;
  font-size: 1.15rem;
`;

export const CardletBody = styled.div`
  > div {
    line-height: 24px;
  }
`;

export const CommunicationIframe = styled.iframe.attrs(({ src }) => ({
  srcDoc: src,
  width: '100%',
  height: '100%',
  title: 'communicationView',
}))`
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CSVLink = styled.a.attrs(({ download }) => ({ download }))`
  display: none;
`;

export const Dd = styled.dd.attrs({ className: 'col-sm-8' })`
  margin-bottom: 0.5rem;
`;

export const Dl = styled.dl.attrs({ className: 'row pl-1' })`
  form + & {
    margin-block-start: 2rem;
  }
`;

export const Dt = styled.dt.attrs({ className: 'col-sm-4' })`
  margin-bottom: 0.5rem;
  &:after {
    content: ':';
  }
`;

export const EditorWrapper = styled.div.attrs(
  ({ extraClassName = '' }: IStyledComponentWithClassName) => ({
    className: `form-control ${extraClassName}`,
  }),
)<IStyledComponentWithClassName>`
  height: auto;
`;

export const Fieldset = styled.fieldset`
  legend {
    font-size: 1rem;
    font-weight: 400;
    color: #3f4254;
    &:after {
      content: ':';
    }
  }
  .filtersForm & {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const FormGroup = styled.div.attrs(
  ({ extraClassName = '' }: IStyledComponentWithClassName) => ({
    className: `form-group ${extraClassName}`,
  }),
)<IStyledComponentWithClassName>`
  .filtersForm & {
    margin-inline-end: 1.75rem;
    margin-block-end: 0;
    min-width: 10rem;
  }
  &.no-bottom-margin {
    margin-bottom: 0;
  }
`;

export const GridCol = styled.div.attrs({ className: 'col-lg-6' })`
  display: flex;
`;

export const GridRow = styled.div.attrs({ className: 'row g-6 g-xl-9' })``;

export const H2 = styled.h2.attrs(
  ({ extraClassName = '' }: IStyledComponentWithClassName) => ({
    className: `card-title align-items-start flex-column ${extraClassName}`,
  }),
)<IStyledComponentWithClassName>``;

export const H3 = styled.h3.attrs(
  ({ extraClassName = '' }: IStyledComponentWithClassName) => ({
    className: extraClassName,
  }),
)<IStyledComponentWithClassName>`
  font-size: 1.15rem;
`;

export const InfoBadge = styled.span.attrs({
  className: 'mr-1 badge badge-info',
})``;

export const InputCheckbox = styled.input.attrs({
  type: 'checkbox',
})`
  cursor: pointer;
`;

interface IInputFileProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
  type: 'file';
}

export const InputFile = styled.input.attrs<IInputFileProps>({
  className: 'custom-file',
  type: 'file',
})``;

export const InputNumber = styled.input.attrs({
  type: 'number',
})`
  display: inline-block;
  margin: 0 0 0 1rem;
  padding: 0 4px;
  border: 1px solid #aaa;
  border-radius: 4px;
  height: 28px;

  &:focus {
    /* One should never, ever use !important, but we're using Bootstrap. 😭 */
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 8%),
      0 0 0 0.2rem rgb(82 195 194 / 25%) !important;
  }
`;

interface IInputTextProps extends IStyledComponentWithClassName {
  type?: React.HTMLInputTypeAttribute;
}

export const InputText = styled.input.attrs(
  ({ extraClassName = '', type = 'text' }: IInputTextProps) => ({
    className: `form-control ${extraClassName}`,
    type,
  }),
)<IInputTextProps>`
  .filtersForm & {
    display: inline-block;
    margin: 0 0 0.35rem 0;
    padding: 0 8px;
    border: 1px solid #aaa;
    border-radius: 4px;
    height: 28px;

    & + button:after {
      background-color: transparent;
      color: #369;
      padding-block-end: 4px;
      font-size: 1.5rem;
      font-weight: 500;
    }
  }
  &:focus {
    /* One should never, ever use !important, but we're using Bootstrap. 😭 */
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 8%),
      0 0 0 0.2rem rgb(82 195 194 / 25%) !important;
  }
`;

interface ILabel {
  checkbox?: boolean;
}

export const Label = styled.label.attrs({ className: 'form-label' })<ILabel>`
  ${({ checkbox }) =>
    checkbox
      ? `
    display: flex;
    align-items: center;
    input {
      margin-inline-end: 0.5rem;
    }
  `
      : ''}
  &:after {
    margin-inline-end: 0.25rem;
  }

  .filtersForm & {
    margin-block-end: 0;
  }
`;

// This wrapper is for preventing weird bugs that happen with table data
// when a user chooses to translate the page with Google Translate.
export const NoTranslateWrapper = styled.div.attrs(() => ({
  className: 'notranslate',
}))``;

export const Select = styled.select.attrs(
  ({ extraClassName = '' }: IStyledComponentWithClassName) => ({
    className: `custom-select ${extraClassName}`,
  }),
)<IStyledComponentWithClassName>`
  .filtersForm &,
  .profile_picker & {
    display: inline-block;
    margin: 0 0 0.35rem 0;
    padding: 0 8px;
    border: 1px solid #aaa;
    border-radius: 4px;
    height: 28px;
  }
  &:focus {
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 8%),
      0 0 0 0.2rem rgb(82 195 194 / 25%);
  }
`;

export const Link = styled(RouterLink)`
  color: var(--heylight-primary);
  &:hover {
    color: var(--heylight-primary);
    text-decoration: underline !important;
    text-underline-offset: 0.25rem;
  }
`;

export const LinkThemed = styled(MuiLink)({
  color: 'var(--heylight-primary)',
  '&:hover': {
    color: 'var(--heylight-primary)',
  },
  textDecorationColor: 'var(--heylight-primary)',
});

export const TextArea = styled.textarea.attrs(
  ({ extraClassName = '' }: IStyledComponentWithClassName) => ({
    className: `form-control ${extraClassName}`,
  }),
)<IStyledComponentWithClassName>`
  &:focus {
    /* One should never, ever use !important, but we're using Bootstrap. 😭 */
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 8%),
      0 0 0 0.2rem rgb(82 195 194 / 25%) !important;
  }
`;

export const NoWrap = styled.span`
  white-space: nowrap;
`;

export const FormControlLabel = styled(MUIFormControlLabel)`
  display: block;
  margin-top: 0px;
`;

export const MuiButtonSpinner = styled(CircularProgress)`
  color: white;
  margin-left: 8px;
  margin-bottom: -2px;
  width: 14px !important;
  height: 14px !important;
`;

export const LoadingSpinner = styled(CircularProgress)`
  color: #666;
  width: 20px !important;
  height: 20px !important;
  margin-left: 10px;
`;

export const DataGrid = styled(props => <MUIDataGrid {...props} />)(
  ({ isCursorPagination = false }) => ({
    minHeight: '200px',
    border: 0,
    '& .MuiDataGrid-columnHeaderTitle': {
      whiteSpace: 'normal',
      lineHeight: 1.2,
      wrap: 'wrap',
      background: 'none',
      fontSize: '.9rem',
      fontWeight: '600',
    },
    '& .MuiDataGrid-columnHeader': {
      background: 'white',
    },
    '& .MuiDataGrid-cell': {
      borderTop: 'none',
    },
    '& .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-footerContainer': {
      borderTop: 'none',
    },
    '& .MuiDataGrid-columnHeader--hover': {
      backgroundColor: 'transparent',
    },
    '& .MuiTablePagination-selectLabel': {
      paddingTop: '10px',
    },
    '& .MuiTablePagination-displayedRows': {
      paddingTop: '12px',
      display: isCursorPagination ? 'none' : 'block',
    },
  }),
);

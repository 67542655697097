/* eslint-disable max-len */
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { useHasPermission } from '@hooks/useHasPermission';
import { GetLocaleDate, Translate, useI18n } from '@hooks/useI18n';
import { useOriginator } from '@hooks/useOriginator';
import {
  IApplicationsSearchApplication,
  IOpsPortalApplicationsSearch,
} from '@schemas/opsPortal/types/applicationsSearch';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';
import { IHeaderSearch } from '../types';

interface IUserGetApplicationSearchQuery {
  merchantUuids: string[];
  search: string;
}

interface IFormatApplicationSearchData {
  data: IOpsPortalApplicationsSearch | undefined;
  getLocaleDate: GetLocaleDate;
  translate: Translate;
}

const formatApplicationSearchData = ({
  data,
  getLocaleDate,
  translate,
}: IFormatApplicationSearchData): IHeaderSearch[] => {
  if (!data?.applications) {
    return [];
  }

  return data.applications.map(
    (application: IApplicationsSearchApplication) => {
      const createdDate = new Date(application.created);
      const createdDateStr = getLocaleDate({
        date: application.created,
        includeTime: true,
      });

      const parts = [
        createdDateStr,
        application.application_reference,
        application.payment_plan_reference,
      ].filter(Boolean);

      return {
        type: translate('OPS_PORTAL.TITLE.APPLICATION'),
        title: parts.join(' - '),
        subTitle: `${application.merchant_name} - ${application.full_name}`,
        redirectUrl: `/applications/${application.external_contract_uuid}`,
        created: createdDate.getTime(),
      };
    },
  );
};

export const useGetApplicationSearchQuery = ({
  merchantUuids,
  search,
}: IUserGetApplicationSearchQuery) => {
  const { originator } = useOriginator();
  const { getLocaleDate, translate } = useI18n();
  const hasPermissions = useHasPermission(['ops_portal.view_application']);

  const {
    data: applicationsData,
    error: applicationsError,
    isLoading: applicationsIsLoading,
  } = useQuery<IOpsPortalApplicationsSearch, AxiosError>(
    [QueryKeys.APPLICATION_SEARCH, search],
    () =>
      fetchDataFromEndpoint(Endpoints.FetchApplicationSearch, {
        query: {
          originator,
          search,
          merchantUuids: merchantUuids.slice(0, 50).join(','),
        },
      }),
    {
      enabled: hasPermissions && Boolean(search),
    },
  );

  return {
    applicationsSearchResults: formatApplicationSearchData({
      data: applicationsData,
      getLocaleDate,
      translate,
    }),
    totalApplicationsCount: applicationsData?.total_applications_count ?? 0,
    applicationsError,
    applicationsIsLoading,
  };
};

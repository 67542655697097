/* eslint-disable max-len, max-lines */
import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import { GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid';
import { Card } from '@components/Card/Card';
import { DataGrid } from '@components/Common.styles';
import { Filters } from '@components/Filters/Filters';
import { NoDataAvailable } from '@components/NoDataAvailable/NoDataAvailable';
import { QueryStateRouter } from '@components/QueryStateRouter/QueryStateRouter';
import { ITableSortingSettings } from '@hooks/useTableSorting/types';
import { TableType } from './enums';
import { defaultPageSizeOptions } from './helpers/constants';
import { useFilterChangeHandler } from './hooks/useFilterChangeHandler';
import { useLimitOffsetPaginationChangeHandler } from './hooks/useLimitOffsetPaginationChangeHandler';
import { useSyncSortSearchParamsEffect } from './hooks/useSyncSortSearchParamsEffect';
import { useSyncURLSearchParamsEffect } from './hooks/useSyncURLSearchParamsEffect';
import {
  IFilterSettings,
  IPaginationSettings,
  IRowSelectionSettings,
  LimitOffsetPaginationModel,
} from './types';

interface ITableLimitOffsetV2<TFilterValues> {
  columnVisibility?: GridColumnVisibilityModel;
  columns: GridColDef[]; // object of fields with boolean values, default to true (visible)
  count: number;
  data: object[];
  error?: AxiosError | null;
  filterSettings?: IFilterSettings<TFilterValues>;
  isInTab?: boolean;
  isLoading?: boolean;
  name: string;
  paginationSettings: IPaginationSettings<LimitOffsetPaginationModel>;
  rowSelectionSettings?: IRowSelectionSettings;
  sortingSettings?: ITableSortingSettings;
  title: string;
  type: TableType.CLIENT | TableType.SERVER;
}

export const TableLimitOffsetV2 = <TFilterValues,>({
  columns = [],
  columnVisibility = {},
  count,
  data = [],
  error,
  filterSettings,
  isInTab = false,
  isLoading,
  name,
  paginationSettings,
  rowSelectionSettings,
  sortingSettings,
  title,
  type = TableType.SERVER,
}: ITableLimitOffsetV2<TFilterValues>) => {
  const [searchParams, setSearchParams] = useSearchParams(
    new URLSearchParams(window.location.search),
  );

  const {
    handlePagination,
    pageNumber,
    pageSize,
    pageSizeOptions = defaultPageSizeOptions,
    paginationParams,
  } = paginationSettings;

  const {
    filterHandler,
    filters,
    filterValues,
    resetFiltersHandler: resetFilters,
    showClearFiltersButton = true,
  } = filterSettings ?? {};
  const { allowRowSelection = false, handleRowSelection } =
    rowSelectionSettings ?? {};
  const { handleSortModelChange, sortModel, sortSearchParam } =
    sortingSettings ?? {};

  useSyncURLSearchParamsEffect({
    searchParams,
    filterHandler,
    handleSortModelChange,
    handlePagination,
  });

  useSyncSortSearchParamsEffect({
    searchParams,
    setSearchParams,
    sortSearchParam,
  });

  const handleFilterChange = useFilterChangeHandler<TFilterValues>({
    filterHandler,
    filterValues,
    paginationParams,
    setSearchParams,
    sortSearchParam,
  });

  const handleLimitOffsetPaginationChange =
    useLimitOffsetPaginationChangeHandler<TFilterValues>({
      filterValues,
      handlePagination,
      setSearchParams,
      sortSearchParam,
      paginationParams,
    });

  const handleFilterReset = useCallback(() => {
    handleFilterChange({});
    handleLimitOffsetPaginationChange({ page: 0, pageSize });
    resetFilters?.();
    handleSortModelChange?.([]);
    setSearchParams({});
  }, [
    handleFilterChange,
    handleLimitOffsetPaginationChange,
    handleSortModelChange,
    pageSize,
    resetFilters,
    setSearchParams,
  ]);

  return (
    <QueryStateRouter error={error} isLoading={isLoading}>
      <Card title={title}>
        {filters ? (
          <Filters
            filterHandler={handleFilterChange}
            filters={filters}
            isInTab={isInTab}
            resetFilters={handleFilterReset}
            showClearFiltersButton={showClearFiltersButton}
          />
        ) : null}
        <DataGrid
          aria-label={name}
          checkboxSelection={allowRowSelection}
          clipboardCopyCellDelimiter=","
          columnHeaderHeight={56}
          columns={columns}
          disableColumnMenu={true}
          disableRowSelectionOnClick={!allowRowSelection}
          initialState={{
            columns: {
              columnVisibilityModel: columnVisibility,
            },
          }}
          onPaginationModelChange={handleLimitOffsetPaginationChange}
          onRowSelectionModelChange={handleRowSelection}
          onSortModelChange={handleSortModelChange}
          pageSizeOptions={pageSizeOptions}
          pagination={true}
          paginationMode={type}
          paginationModel={{
            page: pageNumber,
            pageSize,
          }}
          rowCount={count}
          rowHeight={40}
          rows={data}
          slotProps={{
            noRowsOverlay: {
              children: NoDataAvailable,
            },
          }}
          sortModel={sortModel}
        />
      </Card>
    </QueryStateRouter>
  );
};

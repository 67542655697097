import { useCallback } from 'react';
import { QueryClient, QueryKey } from '@tanstack/react-query';
import { useDownloadSignedCustomerAgreement } from '@hooks/useDownloadSignedCustomerAgreement';
import { useGetQrInvoices } from '@hooks/useGetQrInvoices/useGetQrInvoices';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useDownloadCustomerAgreementModal } from '@hooks/useModal/hooks/useDownloadCustomerAgreementModal';
import { useFileUploadModal } from '@hooks/useModal/hooks/useFileUploadModal';
import { IMerchantSettingObservationViewConfigSetting } from '@schemas/merchantPortal/types/accountSettings';
import { IMerchantPortalTracking } from '@schemas/merchantPortal/types/tracking';
import { ButtonVariant, Endpoints } from '@utils/enums';
import { wait } from '@utils/wait';
import { getIsApplicationSuccessful } from '../utils/getIsApplicationSuccessful';
import { useSendCheckoutSms } from './queries';

interface IUseGetTrackingButtons {
  config: IMerchantSettingObservationViewConfigSetting | undefined;
  contractId?: string;
  data: IMerchantPortalTracking | undefined;
  queryClient: QueryClient;
  queryKey: QueryKey;
}

export const useGetTrackingButtons = ({
  config,
  contractId,
  data,
  queryClient,
  queryKey,
}: IUseGetTrackingButtons) => {
  const { translate } = useI18n();
  const lang = data?.languageCode;

  const { downloadQrInvoiceButton } = useGetQrInvoices({
    externalContractUuid: contractId,
    paymentMethod: data?.paymentMethod,
  });

  const invalidateQuery = async () => {
    // wait 1000ms to allow BE time to update before invalidating the cache
    await wait(1000);
    queryClient.invalidateQueries(queryKey);
  };

  const sendCheckoutSms = useSendCheckoutSms({
    externalContractUUID: contractId,
    lang,
  });

  const isApproved = data && getIsApplicationSuccessful(data?.statusLabel);

  const {
    has_downloaded_agreement: hasDownloadedAgreement,
    has_uploaded_signed_agreement: hasUploadedSignedAgreement,
    is_manual_signing_required: isManualSigningRequired,
  } = data?.manualAgreementSigningOverview ?? {};

  const fileDownloadModal = useDownloadCustomerAgreementModal({
    dependencies: [
      isApproved,
      isManualSigningRequired,
      !hasUploadedSignedAgreement,
    ],
    externalApplicationUuid: contractId,
    queryKey,
    onClose: () => {
      invalidateQuery();
    },
  });

  const fileUploadModal = useFileUploadModal({
    acceptedFiles: ['.pdf', '.jpeg', '.jpg'],
    dependencies: [
      isApproved,
      isManualSigningRequired,
      hasDownloadedAgreement,
      !hasUploadedSignedAgreement,
    ],
    description: translate('PAGE.ORDERS.UPLOAD_CUSTOMER_AGREEMENT_DESCRIPTION'),
    endpoint: `${Endpoints.CustomerAgreement}/${contractId}`,
    onClose: () => {
      invalidateQuery();
    },
  });

  const printPage = useCallback(() => window.print(), []);

  const downloadSignedAgreementButton =
    useDownloadSignedCustomerAgreement(contractId);

  const shouldShowDownloadSignedAgreement =
    downloadSignedAgreementButton &&
    isManualSigningRequired &&
    hasUploadedSignedAgreement;

  const shouldShowSendSmsCheckoutBtn =
    data?.deliveryChannel === 'DELAYED_SMS' &&
    data?.status === 'initialised' &&
    !data.checkoutSmsLinkSent;

  const buttons = [
    ...(config?.showPrintButton
      ? [
          {
            clickHandler: printPage,
            'data-html2canvas-ignore': true,
            key: 'printPage',
            text: translate('BUTTON.PRINT'),
            variant: [ButtonVariant.Primary, ButtonVariant.Sm],
          },
        ]
      : []),
    fileDownloadModal?.button,
    fileUploadModal?.button,
    ...(shouldShowDownloadSignedAgreement
      ? [downloadSignedAgreementButton]
      : []),
    ...(shouldShowSendSmsCheckoutBtn
      ? [
          {
            clickHandler: sendCheckoutSms,
            key: 'sendSms',
            text: translate('BUTTON.SEND_CHECKOUT_SMS'),
            variant: [ButtonVariant.Primary, ButtonVariant.Sm],
          },
        ]
      : []),
    ...downloadQrInvoiceButton,
  ].filter(Boolean);

  return { buttons, fileDownloadModal, fileUploadModal };
};

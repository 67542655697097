import { IGetLimitAndOffset, LimitOffsetPaginationParams } from './types';

export const addIdToRows = (data: object[]) =>
  data?.map((item: object, index: number) => {
    return { ...item, id: index };
  });

export const removeEmptyKeys = <T extends Record<string, unknown>>(
  obj: T,
): Partial<T> => {
  return Object.keys(obj)
    .filter(
      (key: string) =>
        obj[key] !== null && obj[key] !== '' && obj[key] !== undefined,
    )
    .reduce<Partial<T>>((acc, key) => {
      (acc as Record<string, unknown>)[key] = obj[key];
      return acc;
    }, {} as Partial<T>);
};

export const getLimitAndOffset = ({
  pageNumber,
  pageSize,
}: IGetLimitAndOffset): LimitOffsetPaginationParams => {
  const offset = pageSize * pageNumber;
  return {
    limit: pageSize !== 10 ? pageSize : 10,
    offset: offset && offset !== 0 ? offset : 0,
  };
};

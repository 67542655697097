import {
  FooterLink,
  LoginFooter,
  LoginFooterNav,
  LoginSidePanel,
  SidePanelInnerWrapper,
} from '@auth/pages/Auth.styles';
import { useConfig } from '@hooks/useConfig';
import { useGetLinks } from '@hooks/useGetLinks';
import { useI18n } from '@hooks/useI18n/useI18n';
import { Copyright } from './Copyright';
import { LoginGreeting } from './LoginGreeting';

export const SidePanel = () => {
  const { translate } = useI18n();
  const { showFooter } = useConfig();
  const { privacyPolicy } = useGetLinks();

  return (
    <LoginSidePanel>
      <SidePanelInnerWrapper>
        <LoginGreeting />

        {showFooter ? (
          <LoginFooter>
            <Copyright
              companyLegalName={translate('GENERAL.COMPANY_LEGAL_NAME')}
            />
            <LoginFooterNav>
              <FooterLink href={privacyPolicy}>
                {translate('LINK.PRIVACY')}
              </FooterLink>
            </LoginFooterNav>
          </LoginFooter>
        ) : null}
      </SidePanelInnerWrapper>
    </LoginSidePanel>
  );
};

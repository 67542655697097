import { AxiosError } from 'axios';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { IConsumerPortalSettlementsMoneta } from '@schemas/consumerPortal/types/settlementsMoneta';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';

interface IUseGetMonetaSettlementsQuery {
  contractUuid?: string;
}

export const useGetMonetaSettlementsQuery = ({
  contractUuid,
}: IUseGetMonetaSettlementsQuery) => {
  const {
    data: monetaSettlementsData,
    error,
    isLoading: isLoadingMonetaSettlementsData,
  } = useQuery<IConsumerPortalSettlementsMoneta, AxiosError>(
    [QueryKeys.MONETA_SETTLEMENTS_DATA, contractUuid],
    () =>
      fetchDataFromEndpoint(
        `${Endpoints.FetchMonetaSettlementsData}/${contractUuid}`,
        {},
      ),
    {
      enabled: Boolean(contractUuid),
    },
  );

  const queryClient = useQueryClient();
  const invalidateMonetaSettlementsData = () => {
    queryClient.invalidateQueries([
      QueryKeys.MONETA_SETTLEMENTS_DATA,
      contractUuid,
    ]);
  };

  return {
    error,
    isLoadingMonetaSettlementsData,
    invalidateMonetaSettlementsData,
    monetaSettlementsData,
  };
};

import * as privacyPolicies from '@assets/privacyPolicies';
import { getNavData } from '@hooks/useConfig/utils';
import { HotjarIds } from '@utils/constants';
import { ConfigFunc } from './types';

const { NavItems, NavSectionHeadingKeys } = getNavData();

export const configMerchantHeidipay: ConfigFunc = ({
  merchantSettingGroup,
}) => {
  const { hideOrdersPage, showApplicationsPage, showMerchantStoresPage } =
    merchantSettingGroup;

  return Object.freeze({
    canChangePassword: true,
    canEditAddress: false,
    canEditName: true,
    canEditOrgInfo: true,
    canEditPhone: false,
    hotjarId: HotjarIds.HEIDIPAY,
    navigation: [
      {
        heading: NavSectionHeadingKeys.CUSTOMERS,
        items: [
          NavItems.CREATE_NEW_PLAN,
          ...(showApplicationsPage
            ? [NavItems.MY_APPLICATIONS, NavItems.APPLICATIONS]
            : []),
          ...(hideOrdersPage
            ? []
            : [
                NavItems.MY_ORDERS,
                NavItems.ORDERS,
                NavItems.AWAITING_CONFIRMATION,
                NavItems.SETTLEMENTS,
              ]),
        ],
      },
      {
        heading: NavSectionHeadingKeys.ORGANISATION,
        items: [
          NavItems.ORG_INFO,
          NavItems.USERS,
          ...(showMerchantStoresPage ? [NavItems.MERCHANT_STORES] : []),
        ],
      },
      {
        heading: NavSectionHeadingKeys.HELP,
        items: [NavItems.MERCHANT_GUIDE],
      },
      {
        heading: NavSectionHeadingKeys.ACCOUNT_MANAGEMENT,
        items: [
          NavItems.ACCOUNT_SETTINGS,
          NavItems.MERCHANT_PRIVACY_POLICY,
          NavItems.LOGOUT,
        ],
      },
    ],
    privacyPolicy: privacyPolicies.HeyLight,
    showCompassOrders: false,
    showFooter: true,
    showFooterPrivacyLink: false,
    showInvoiceDetails: false,
    showLanguageDropdown: true,
    showOrgIndustry: true,
    showOrgPrimaryColour: true,
    showOrgSMS: true,
    showProfilePicker: true,
    supportEmail: 'heylight-support@heidipay.com',
  });
};

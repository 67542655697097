import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ICursors } from '@hooks/useCursorPagination/types';
import { ISortingParam } from '@hooks/useTableSorting/types';
import { removeEmptyKeys } from '../helpers/utils';
import { PaginationParams } from '../types';

interface IUseFilterChangeHandler<T> {
  cursors?: ICursors;
  filterHandler?: (values: T) => void;
  filterValues?: T;
  paginationParams: PaginationParams;
  setSearchParams: ReturnType<typeof useSearchParams>[1];
  sortSearchParam?: ISortingParam;
}

export const useFilterChangeHandler = <T>({
  cursors,
  filterHandler,
  filterValues,
  paginationParams,
  setSearchParams,
  sortSearchParam,
}: IUseFilterChangeHandler<T>) =>
  useCallback(
    (newFilterValues: Partial<T>) => {
      filterHandler?.(removeEmptyKeys(newFilterValues) as T);

      // This needs to handle multiple tables' filters on one page like the current mechanism
      // Ticket - https://heidi-pay.atlassian.net/browse/HC-12836

      // This is setting the URL Params so the page can be reloaded
      // And the filters and pagination still persists
      const updatedPaginationParamsWithFiltersForURL = removeEmptyKeys({
        ...filterValues,
        ...newFilterValues, // Overrides with new filter values
        ...sortSearchParam,
        limit: paginationParams.limit.toString(),
        offset: paginationParams?.offset?.toString() || '0',

        // Currently not working for cursor pagination - cursor logic needs re-working
        // Tickets:
        // - https://heidi-pay.atlassian.net/browse/HC-12837
        // - https://heidi-pay.atlassian.net/browse/HC-12836
        cursor: cursors?.cursor || '',
      });

      const urlSearchParams = Object.fromEntries(
        Object.entries(updatedPaginationParamsWithFiltersForURL).map(
          ([key, value]) => [key, String(value)],
        ),
      );

      setSearchParams(urlSearchParams);
    },
    [
      cursors,
      filterHandler,
      filterValues,
      paginationParams,
      setSearchParams,
      sortSearchParam,
    ],
  );

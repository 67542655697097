import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ISortingParam } from '@hooks/useTableSorting/types';
import { removeEmptyKeys } from '../helpers/utils';

interface IUseSyncSortSearchParamsEffectParams {
  searchParams: URLSearchParams;
  setSearchParams: ReturnType<typeof useSearchParams>[1];
  sortSearchParam?: ISortingParam;
}

export const useSyncSortSearchParamsEffect = ({
  searchParams,
  setSearchParams,
  sortSearchParam,
}: IUseSyncSortSearchParamsEffectParams) => {
  useEffect(() => {
    setSearchParams(
      removeEmptyKeys({
        ...Object.fromEntries(searchParams),
        ...sortSearchParam,
      }),
    );
  }, [sortSearchParam, searchParams, setSearchParams]);
};

import { useEffect } from 'react';
import { AxiosError } from 'axios';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { useMutation } from '@tanstack/react-query';
import { usePortalError } from '@hooks/usePortalError';
import { IConsumerDetailsContracts } from '@schemas/opsPortal/types/consumerDetails';
import { IContracts } from '@schemas/opsPortal/types/contracts';
import { Endpoints } from '@utils/enums';
import { postDataToEndpoint } from '@utils/postDataToEndpoint';
import {
  IContractExtraDataRequest,
  IContractExtraDataResponse,
} from '../types';

// lazy load extra data for the contracts list, once we have the initial list of contracts shown
export const useGetContractsExtraData = (
  contractsData?: IContracts | IConsumerDetailsContracts,
) => {
  const { handleAxiosError } = usePortalError();

  const { data: contractsExtraData, mutate: getContractsExtraData } =
    useMutation<
      IContractExtraDataResponse,
      AxiosError<IError>,
      IContractExtraDataRequest
    >(
      requestData =>
        postDataToEndpoint({
          endpoint: Endpoints.GetContractsExtraData,
          requestData,
        }),
      {
        onError: error => handleAxiosError(error, 'FAILED'),
      },
    );

  useEffect(() => {
    if (contractsData) {
      getContractsExtraData({
        external_contract_uuid: contractsData.map(
          contract => contract.external_uuid,
        ),
      });
    }
  }, [contractsData, getContractsExtraData]);

  return contractsExtraData;
};

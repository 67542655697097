import { useCallback } from 'react';
import { IActionButton } from '@appTypes';
import { CardButton } from '@components/CardButton';
import { useI18n } from '@hooks/useI18n/useI18n';
import { CommunicationsTypes } from '@utils/constants';
import { ICommunication } from './types';

interface IViewCommunicationButtonProps {
  button: IActionButton;
  communicationProps: ICommunication;
  setCommunicationProps: (communicationProps: ICommunication) => void;
}

export const ViewCommunicationButton = ({
  button,
  communicationProps,
  setCommunicationProps,
}: IViewCommunicationButtonProps) => {
  const { translate } = useI18n();
  const clickHandler = useCallback(() => {
    setCommunicationProps(communicationProps);
    button.clickHandler();
  }, [communicationProps, button, setCommunicationProps]);

  const updatedButtonProps = {
    ...button,
    clickHandler,
    text: translate(
      communicationProps.type === CommunicationsTypes.EMAIL
        ? 'OPS_PORTAL.BUTTON.VIEW_EMAIL'
        : 'OPS_PORTAL.BUTTON.VIEW_TEXT_MESSAGE',
    ),
  };

  return <CardButton {...updatedButtonProps} />;
};

import { Tabs } from '@components/Tabs';
import { useCollectionAgencies } from '@hooks/useCollectionAgencies';
import { useI18n } from '@hooks/useI18n/useI18n';
import { usePortal } from '@hooks/usePortal';
import { mapTabData } from '@pages/ConsumerDetails/utils';
import { useGetContractsExtraData } from '@pages/Contracts/queries';
import { IApplications } from '@schemas/opsPortal/types/applications';
import { IOpsPortalConsumerDetails } from '@schemas/opsPortal/types/consumerDetails';
import { TabEventKeys } from '@utils/constants';

interface ICardDisplayProps {
  applications?: IApplications;
  data: IOpsPortalConsumerDetails;
}

export const CardDisplay = ({ applications, data }: ICardDisplayProps) => {
  const { getLocaleCurrencyValue, getLocaleDate, translate } = useI18n();
  const { isMerchantPortal } = usePortal();

  const { collectionAgencies } = useCollectionAgencies();

  const contractsExtraData = useGetContractsExtraData(
    data?.consumer_details?.contracts,
  );

  const tabsData = mapTabData({
    applications,
    collectionAgencies,
    data,
    getLocaleCurrencyValue,
    getLocaleDate,
    translate,
    isMerchantPortal,
    contractsExtraData,
  });

  return (
    <Tabs
      initialTabKey={TabEventKeys.CONSUMER_DETAILS}
      tabsData={tabsData}
      tabsId="consumer-details-tabs"
    />
  );
};

import { useCallback } from 'react';
import { IActionButton } from '@appTypes';
import { CardButton } from '@components/CardButton';

interface IViewEarlySettlementButtonProps {
  button: IActionButton;
  earlySettlementQuoteUuid: string;
  setEarlySettlementQuoteUuid: (earlySettlementQuoteUuid: string) => void;
}

export const ViewEarlySettlementButton = ({
  button,
  earlySettlementQuoteUuid,
  setEarlySettlementQuoteUuid,
}: IViewEarlySettlementButtonProps) => {
  const clickHandler = useCallback(() => {
    setEarlySettlementQuoteUuid(earlySettlementQuoteUuid);
    button.clickHandler();
  }, [button, earlySettlementQuoteUuid, setEarlySettlementQuoteUuid]);

  return <CardButton {...button} clickHandler={clickHandler} />;
};

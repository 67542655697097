import { useCallback } from 'react';
import axios, { AxiosError } from 'axios';
import { useToast } from '@heidi-pay/heidi-common-fe/hooks';
import { IError } from '@heidi-pay/heidi-common-fe/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useModal } from '@hooks/useModal';
import { getModalDetails } from '@hooks/useModal/getModalDetails';
import { useConfirmModal } from '@hooks/useModal/hooks/useConfirmModal';
import { ISharedModalProps, Modal } from '@hooks/useModal/types';
import { usePortalError } from '@hooks/usePortalError';
import { QueryKeys } from '@utils/constants';
import { ButtonVariant, Endpoints } from '@utils/enums';
import { IDocumentsTableColumn } from './types';

export interface IDeleteDocumentProps extends ISharedModalProps {
  rowData: IDocumentsTableColumn;
}

export const useDeleteDocumentModal = (props: IDeleteDocumentProps): Modal => {
  const { rowData } = props;
  const { translate } = useI18n();
  const { handleAxiosError } = usePortalError();
  const { message } = useToast(translate);
  const queryClient = useQueryClient();

  const modal = useModal({
    sharedProps: props,
    modalName: 'deleteDocumentModal',
    permissions: ['ops_portal.can_delete_uploaded_documents'],
    buttonTranslationKey: 'OPS_PORTAL.BUTTON.DELETE',
  });

  const { mutate } = useMutation<
    void,
    AxiosError<IError>,
    IDocumentsTableColumn
  >(
    async inputData => {
      const url = `/api/${Endpoints.DeleteUploadedDocument}/${inputData.uuid}/`;
      const { data } = await axios.post(url, {});
      return data;
    },
    {
      onError: error => handleAxiosError(error),
      onSuccess: () => {
        message.success('OPS_PORTAL.STATUS.SUCCESFULLY_DELETED_UPLOADED_FILE');

        queryClient.invalidateQueries([
          QueryKeys.CONTRACT_DOCUMENTS_OPS_USER_DOCUMENTS,
        ]);

        modal.closeModal();
      },
    },
  );

  const handleSubmit = useCallback(() => {
    mutate(rowData);
  }, [mutate, rowData]);

  const modalProps = useConfirmModal({
    children: <>{translate('GENERAL.ARE_YOU_SURE')}</>,
    onCancel: modal.closeModal,
    onConfirm: handleSubmit,
    title: translate('OPS_PORTAL.TITLE.DELETE_UPLOADED_DOCUMENT'),
    variant: [ButtonVariant.Danger, ButtonVariant.Sm],
  });

  return getModalDetails({ modal, modalProps });
};

import { useCallback } from 'react';
import { IActionButton } from '@appTypes';
import { CardButton } from '@components/CardButton';

interface IViewRawDataButtonProps {
  button: IActionButton;
  setUuid: (uuid: string) => void;
  uuid: string;
}

export const ViewRawDataButton = ({
  button,
  setUuid,
  uuid,
}: IViewRawDataButtonProps) => {
  const clickHandler = useCallback(() => {
    setUuid(uuid);
    button.clickHandler();
  }, [uuid, button, setUuid]);

  return <CardButton {...button} clickHandler={clickHandler} />;
};

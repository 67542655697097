import { AxiosError } from 'axios';
import { useQueries } from '@tanstack/react-query';
import { IOpsPortalContractDocumentsV2 } from '@schemas/opsPortal/types/contractDocumentsV2';
import { QueryKeys } from '@utils/constants';
import { Endpoints } from '@utils/enums';
import { fetchDataFromEndpoint } from '@utils/fetchDataFromEndpoint';

interface IUseContractDocumentsTab {
  contractUuid: string;
}

export const useGetContractDocumentsQuery = ({
  contractUuid,
}: IUseContractDocumentsTab) => {
  const enabled = Boolean(contractUuid);
  const results = useQueries<
    [
      [IOpsPortalContractDocumentsV2, AxiosError],
      [IOpsPortalContractDocumentsV2, AxiosError],
      [IOpsPortalContractDocumentsV2, AxiosError],
      [IOpsPortalContractDocumentsV2, AxiosError],
      [IOpsPortalContractDocumentsV2, AxiosError],
    ]
  >({
    queries: [
      {
        queryKey: [
          QueryKeys.CONTRACT_DOCUMENTS_CUSTOMER_AGREEMENT,
          contractUuid,
        ],
        queryFn: () =>
          fetchDataFromEndpoint(
            `${Endpoints.FetchContractDocumentsCustomerAgreement}/${contractUuid}`,
          ),
        enabled,
      },
      {
        queryKey: [QueryKeys.CONTRACT_DOCUMENTS_ONFIDO, contractUuid],
        queryFn: () =>
          fetchDataFromEndpoint(
            `${Endpoints.FetchContractDocumentsOnfido}/${contractUuid}`,
          ),
        enabled,
      },
      {
        queryKey: [QueryKeys.CONTRACT_DOCUMENTS_INTRUM, contractUuid],
        queryFn: () =>
          fetchDataFromEndpoint(
            `${Endpoints.FetchContractDocumentsIntrum}/${contractUuid}`,
          ),
        enabled,
      },
      {
        queryKey: [
          QueryKeys.CONTRACT_DOCUMENTS_TERMS_AND_CONDITIONS,
          contractUuid,
        ],
        queryFn: () =>
          fetchDataFromEndpoint(
            `${Endpoints.FetchContractDocumentsTermsAndConditions}/${contractUuid}`,
          ),
        enabled,
      },
      {
        queryKey: [
          QueryKeys.CONTRACT_DOCUMENTS_OPS_USER_DOCUMENTS,
          contractUuid,
        ],
        queryFn: () =>
          fetchDataFromEndpoint(
            `${Endpoints.FetchContractDocumentOpsUserDocuments}/${contractUuid}`,
          ),
        enabled,
      },
    ],
  });

  const isLoading = results.some(result => result.isLoading);
  const data = results
    .flatMap(result => result.data?.documents || [])
    .sort(
      (a, b) => new Date(b.created).valueOf() - new Date(a.created).valueOf(),
    );
  const error = results.find(result => result.error)?.error || null;
  return { data, error, isLoading };
};

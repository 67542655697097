import { styled } from '@mui/material/styles';
import { Card } from '@components/Card';
import { H3 } from '@components/Common.styles';
import { useI18n } from '@hooks/useI18n/useI18n';
import privacyPolicy from './privacyPolicyHeyLight.json';

const PrivacyPolicySectionContainer = styled('div')({
  paddingBottom: '20px',
});

interface IPrivacyPolicySection {
  contentSections: string[];
  subTitle?: string;
  title: string;
}

const PrivacyPolicySection = ({
  contentSections,
  subTitle,
  title,
}: IPrivacyPolicySection) => {
  return (
    <PrivacyPolicySectionContainer>
      <H3>{title}</H3>
      {subTitle ? <p role="doc-subtitle">{subTitle}</p> : null}
      {contentSections.map(section => (
        <p key={section}>{section}</p>
      ))}
    </PrivacyPolicySectionContainer>
  );
};

export const HeyLight = () => {
  const { translate } = useI18n();

  const cardTitleKey = `TITLE.${privacyPolicy.title
    .toUpperCase()
    .replace(/\s/g, '_')}`;
  return (
    <Card title={translate(cardTitleKey)}>
      <PrivacyPolicySectionContainer>
        {privacyPolicy.introductionSections.map(section => (
          <p key={section}>{section}</p>
        ))}
      </PrivacyPolicySectionContainer>
      <PrivacyPolicySection
        contentSections={
          privacyPolicy.what_information_do_we_use.contentSections
        }
        subTitle={privacyPolicy.what_information_do_we_use.subTitle}
        title={privacyPolicy.what_information_do_we_use.title}
      />
      <PrivacyPolicySection
        contentSections={
          privacyPolicy.what_purpose_do_we_process_your_data.contentSections
        }
        title={privacyPolicy.what_purpose_do_we_process_your_data.title}
      />
      <PrivacyPolicySection
        contentSections={privacyPolicy.revoking_consent.contentSections}
        title={privacyPolicy.revoking_consent.title}
      />
      <PrivacyPolicySection
        contentSections={
          privacyPolicy.who_might_we_share_your_personal_data_with
            .contentSections
        }
        title={privacyPolicy.who_might_we_share_your_personal_data_with.title}
      />
      <PrivacyPolicySection
        contentSections={
          privacyPolicy.where_do_we_process_your_personal_data.contentSections
        }
        title={privacyPolicy.where_do_we_process_your_personal_data.title}
      />
      <PrivacyPolicySection
        contentSections={
          privacyPolicy.how_long_do_we_process_your_personal_data
            .contentSections
        }
        title={privacyPolicy.how_long_do_we_process_your_personal_data.title}
      />
      <PrivacyPolicySection
        contentSections={
          privacyPolicy.your_rights_in_regards_to_the_personal_data
            .contentSections
        }
        title={privacyPolicy.your_rights_in_regards_to_the_personal_data.title}
      />
      <PrivacyPolicySection
        contentSections={
          privacyPolicy.what_about_cookies_and_other_tracking_technologies
            .contentSections
        }
        title={
          privacyPolicy.what_about_cookies_and_other_tracking_technologies.title
        }
      />
      <PrivacyPolicySection
        contentSections={privacyPolicy.revoking_consent.contentSections}
        title={privacyPolicy.updates_to_this_merchant_privacy_notice.title}
      />
      <PrivacyPolicySection
        contentSections={privacyPolicy.revoking_consent.contentSections}
        title={privacyPolicy.contact_details.title}
      />
      <p>{privacyPolicy.last_updated}</p>
    </Card>
  );
};

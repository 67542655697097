import styled from 'styled-components';

export const PageSizeLabel = styled.label<{ isInline?: boolean }>`
  width: fit-content;
  height: height;
  margin: 0 1rem 0 0;
  line-height: 1.2;
  font-size: 1rem;
  font-weight: normal;

  &:after {
    content: ':';
  }

  select {
    height: 28px;
  }
`;

const PaginationButton = styled.button`
  min-width: 2.25rem;
  padding: 0.5rem;
  margin-inline-start: 0.125rem;
  margin-inline-end: 0.125rem;
  background: transparent;
  border: 1px solid transparent;
  border-radius: 2px;
  font-weight: 500;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease;
  color: var(--heylight-primary);

  &:active,
  &:hover {
    background: darkgrey;
    color: #ffffff;
  }
`;

export const PaginationCounter = styled.p`
  margin-block-end: 0;
`;

export const PaginationCurrentPage = styled.span.attrs({
  className: 'current',
})`
  display: inline-block;
  min-width: 1.5rem;
  margin-inline-start: 0.5rem;
  margin-inline-end: 0.5rem;
  padding: 0.5rem;
  background-color: white;
  background: linear-gradient(to bottom, #fff 0%, #dcdcdc 100%);
  border: 1px solid #979797;
  border-radius: 2px;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1;
  color: #333;
`;

export const PaginationGotoButton = styled(PaginationButton)`
  min-width: 1.5rem;
  margin-inline-start: 0;
  margin-inline-end: 0;
  font-size: 1rem;
  line-height: 1;
`;

export const PaginationPlaceholder = styled.span`
  margin-inline-start: 0.5rem;
  margin-inline-end: 0.5rem;
`;

export const PaginationStepButton = styled(PaginationButton)`
  &:disabled {
    color: #999;
    cursor: not-allowed;
    &:active,
    &:hover {
      background: transparent;
      border-color: transparent;
      color: #999;
    }
  }
`;

export const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

import { useRef } from 'react';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Popper from '@mui/material/Popper';
import TextField from '@mui/material/TextField';
import { ClearIcon } from '@mui/x-date-pickers/icons';
import { LoadingSpinner } from '@components/Common.styles';
import { useHasPermission } from '@hooks/useHasPermission';
import { useI18n } from '@hooks/useI18n';
import { usePortal } from '@hooks/usePortal';
import { Portals } from '@utils/constants';
import { ExtraResultsMessage } from './ExtraResultsMessage';
import { useGetHeaderSearchData } from './hooks/useGetHeaderSearchData';
import {
  SearchContainer,
  SearchItem,
  SearchList,
  SearchListItem,
  SearchSubTitle,
  SearchTitle,
} from './styled';
import { IHeaderSearch } from './types';

export const HeaderSearch = () => {
  const { portal } = usePortal();
  const { translate } = useI18n();
  const inputEl = useRef<HTMLInputElement | null>(null);

  const hasViewSearchPermission = useHasPermission([
    'ops_portal.can_view_ops_global_search',
  ]);

  const hasViewPagesPermissions = useHasPermission([
    'ops_portal.view_application',
    'ops_portal.view_contract',
  ]);

  const canViewSearchHeader =
    portal === Portals.OPS &&
    hasViewSearchPermission &&
    hasViewPagesPermissions;

  const {
    handleBlur,
    handleClearSearch,
    handleSearchChange,
    handleSelect,
    hasSearchResults,
    loading,
    open,
    search,
    searchResults,
    totalApplicationsCount,
    totalContractsCount,
  } = useGetHeaderSearchData(canViewSearchHeader);

  return canViewSearchHeader ? (
    <>
      <TextField
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {loading && <LoadingSpinner />}
              {search && (
                <IconButton onClick={handleClearSearch} size="small">
                  <ClearIcon />
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
        inputRef={inputEl}
        label={translate('LABEL.SEARCH')}
        onBlur={handleBlur}
        onChange={handleSearchChange}
        size="small"
        sx={{ width: 400, marginRight: '20px' }}
        value={search}
      />
      <Popper
        anchorEl={inputEl.current}
        open={open}
        placement="bottom-start"
        sx={{ zIndex: 1000 }}
      >
        <SearchContainer>
          <SearchList>
            {hasSearchResults
              ? searchResults.map(
                  ({ redirectUrl, subTitle, title, type }: IHeaderSearch) => (
                    <SearchListItem
                      key={title}
                      onClick={handleSelect(redirectUrl)}
                    >
                      <SearchItem>
                        <SearchTitle>
                          <b>{type}</b> - {title}
                        </SearchTitle>
                        <SearchSubTitle>{subTitle}</SearchSubTitle>
                      </SearchItem>
                    </SearchListItem>
                  ),
                )
              : null}
            {!hasSearchResults && !loading ? (
              <SearchListItem>
                {translate('STATUS.NO_DATA_AVAILABLE')}
              </SearchListItem>
            ) : null}
            {loading ? (
              <SearchListItem>{translate('STATUS.LOADING')}</SearchListItem>
            ) : null}
            <ExtraResultsMessage
              totalApplicationsCount={totalApplicationsCount}
              totalContractsCount={totalContractsCount}
              translate={translate}
            />
          </SearchList>
        </SearchContainer>
      </Popper>
    </>
  ) : null;
};

import { objectKeysToCamelCase } from '@heidi-pay/heidi-common-fe/utils/objectKeysToCamelCase';
import { IDataRow } from '@components/DataDisplay/DataDisplay';
import { GetLocaleDate, Translate } from '@hooks/useI18n';
import { IOpsPortalIban } from '@schemas/opsPortal/types/iban';
import { IMerchant } from '@schemas/opsPortal/types/merchants';
import { addPlaceholderForEmptyValues } from '@utils/addPlaceholderForEmptyValues';
import { convertBooleanToYesOrNo } from '@utils/convertBooleanToYesOrNo';

interface IGetDataListProps extends Partial<IOpsPortalIban> {
  canViewIban: boolean;
  data: IMerchant;
  getLocaleDate: GetLocaleDate;
  translate: Translate;
}

export const getDataList = ({
  canViewIban,
  data,
  getLocaleDate,
  iban,
  translate,
}: IGetDataListProps) => {
  const {
    availableLanguages,
    country,
    createdDate,
    externalUuid,
    industryName,
    legalName,
    merchantSettlementPaused,
    shortName,
    vatNumber,
    websiteUrl,
  } = objectKeysToCamelCase(data);

  return [
    { key: translate('LABEL.LEGAL_NAME'), value: legalName },
    { key: translate('OPS_PORTAL.LABEL.SHORT_NAME'), value: shortName },
    { key: translate('TITLE.UUID'), value: externalUuid },
    { key: translate('OPS_PORTAL.LABEL.COUNTRY'), value: country },
    {
      key: translate('OPS_PORTAL.LABEL.AVAILABLE_LANGUAGES'),
      value: availableLanguages
        ?.map(languageGroup =>
          translate(
            `OPS_PORTAL.LABEL.${languageGroup.languageCode.toUpperCase()}`,
          ),
        )
        .join(', '),
    },
    {
      key: translate('OPS_PORTAL.LABEL.MERCHANT_INDUSTRY'),
      value: industryName,
    },
    { key: translate('OPS_PORTAL.LABEL.WEBSITE_URL'), value: websiteUrl },
    {
      key: translate('OPS_PORTAL.TITLE.CREATED_DATE'),
      value: createdDate ? getLocaleDate({ date: createdDate }) : '',
    },
    { key: translate('LABEL.VAT_REGISTRATION_NUMBER'), value: vatNumber },
    {
      key: translate('OPS_PORTAL.LABEL.MERCHANT_SETTLEMENT_PAUSED'),
      value: convertBooleanToYesOrNo(merchantSettlementPaused, translate),
    },
    ...(canViewIban
      ? [{ key: translate('TITLE.BANK_ACCOUNT_NUMBER'), value: iban }]
      : []),
  ].map(val => addPlaceholderForEmptyValues<IDataRow>(val));
};

/* eslint-disable sonarjs/cognitive-complexity */
import { createColumnHelper } from '@tanstack/react-table';
import { Translate } from '@hooks/useI18n/types';
import { SortingFunctions } from '@utils/constants';
import { IMappedConsumerPaymentsData } from '../types';

interface IGetConsumerPaymentsColumns {
  translate: Translate;
}

export const getConsumerPaymentsColumns = ({
  translate,
}: IGetConsumerPaymentsColumns) => {
  const columnHelper = createColumnHelper<IMappedConsumerPaymentsData>();

  return [
    columnHelper.accessor('amount', {
      header: translate('LABEL.AMOUNT'),
      sortingFn: SortingFunctions.CURRENCY,
    }),
    columnHelper.accessor('settlementDate', {
      header: translate('OPS_PORTAL.LABEL.CUSTOMER_PAYMENT_DATE'),
      sortingFn: SortingFunctions.DATETIME,
    }),
    columnHelper.accessor('settlementType', {
      header: translate('OPS_PORTAL.LABEL.PAID_VIA'),
    }),
    columnHelper.accessor('customerRefund', {
      header: translate('TYPE.REFUND'),
    }),
  ];
};

import { useParams } from 'react-router-dom';
import { AccessController } from '@components/AccessController';
import { ApiKeys } from '@components/ApiKeys';
import { Card } from '@components/Card';
import { DataDisplay } from '@components/DataDisplay';
import { NoDataAvailable } from '@components/NoDataAvailable';
import { QueryStateRouter } from '@components/QueryStateRouter';
import { useHasPermission } from '@hooks/useHasPermission';
import { useI18n } from '@hooks/useI18n/useI18n';
import { useChangeIbanModal } from '@hooks/useModal/hooks/useChangeIbanModal';
import { getDataList } from './getDataList';
import { useGetMerchantDetails, useGetPauseSettlementButton } from './hooks';

export const MerchantDetails = () => {
  const { getLocaleDate, translate } = useI18n();
  const { uuid } = useParams();

  const canViewIban = useHasPermission(['ops_portal.view_merchant_iban']);

  const { dataIban, dataMerchantDetails, errorMerchantDetails, isLoading } =
    useGetMerchantDetails({ uuid, canViewIban });

  const dataList =
    dataMerchantDetails &&
    getDataList({
      canViewIban,
      data: dataMerchantDetails,
      getLocaleDate,
      translate,
      ...(canViewIban && dataIban ? { iban: dataIban.iban } : {}),
    });

  const changeIbanModal = useChangeIbanModal({
    dependencies: [canViewIban],
    iban: dataIban?.iban,
    merchantUuid: dataMerchantDetails?.external_uuid,
  });

  const pauseMerchantSettlementButton = useGetPauseSettlementButton({
    uuid,
    merchantSettlementPaused: dataMerchantDetails?.merchant_settlement_paused,
  });

  const buttons = [
    ...(pauseMerchantSettlementButton ? [pauseMerchantSettlementButton] : []),
    ...(changeIbanModal ? [changeIbanModal.button] : []),
  ];

  return (
    <>
      <Card
        buttons={buttons}
        title={translate('OPS_PORTAL.TITLE.MERCHANT_DETAILS')}
      >
        <QueryStateRouter error={errorMerchantDetails} isLoading={isLoading}>
          {dataList ? <DataDisplay dataList={dataList} /> : <NoDataAvailable />}
        </QueryStateRouter>
      </Card>
      <AccessController scopes={['ops_portal.can_view_merchants_keys']}>
        <ApiKeys />
      </AccessController>
    </>
  );
};

import { useEffect } from 'react';
import { usePortal } from '@hooks/usePortal';
import { setGrafanaSession } from '../setGrafanaSession';

export const useSetGrafanaPortal = () => {
  const { portal } = usePortal();

  useEffect(() => {
    if (portal) {
      setGrafanaSession({ portal });
    }
  }, [portal]);
};

import { useMemo, useState } from 'react';
import { Translate } from '@heidi-pay/heidi-common-fe/types';
import { IFilterSettings } from '@components/TableV2/types';
import { IOpsPortalMerchantsList } from '@schemas/opsPortal/types/merchantsList';
import { FilterTypes } from '@utils/constants';
import { getMerchantsOptions } from '@utils/getMerchantsOptions/getMerchantsOptions';
import { ICollectionsFilterValues } from './types';

interface IUseGetCollectionsFilters {
  initialDefaultValues: ICollectionsFilterValues;
  merchantsData?: IOpsPortalMerchantsList;
  translate: Translate;
}

export const useGetCollectionsFilters = ({
  initialDefaultValues,
  merchantsData,
  translate,
}: IUseGetCollectionsFilters): IFilterSettings<ICollectionsFilterValues> => {
  const [filterValues, setFilterValues] =
    useState<ICollectionsFilterValues>(initialDefaultValues);
  const filterHandler = (values: ICollectionsFilterValues) =>
    setFilterValues(values);
  const resetFiltersHandler = () => {
    setFilterValues(initialDefaultValues);
  };

  const merchantsOptions = useMemo(
    () => getMerchantsOptions(merchantsData),
    [merchantsData],
  );

  return {
    filters: [
      {
        id: 'merchant',
        label: translate('OPS_PORTAL.TITLE.MERCHANTS'),
        options: merchantsOptions,
        type: FilterTypes.DROPDOWN,
        value: filterValues?.merchant,
      },
      {
        id: 'performanceStatus',
        label: 'Performance status',
        options: [
          { id: '', name: '—' },
          { id: 'performing', name: translate('STATUS.PERFORMING') },
          { id: 'delayed', name: translate('STATUS.DELAYED') },
          { id: 'overdue', name: translate('STATUS.OVERDUE') },
          { id: 'completed', name: translate('STATUS.TRACKING_COMPLETED') },
        ],
        type: FilterTypes.DROPDOWN,
        value: filterValues?.performanceStatus,
      },
      {
        id: 'search',
        label: translate('LABEL.SEARCH'),
        type: FilterTypes.SEARCH,
        value: filterValues?.search,
      },
    ],
    filterHandler,
    resetFiltersHandler,
    filterValues,
  };
};
